import * as actions from '../actions/actionTypes';

const initialState = {
    token: '',
    refreshToken: '',
    userEmail: '',
    userID: '',
    publisherID: '',
    authorization: [],
    expirationDate: '',

    name: '',
    surname: '',
    congregation: '',

    logoutMessage: '',
    errorMessage: '',
    regSuccessMessage: '',

    loading: false,
    regSuccess: false,
    openedLoginScreen: false,
    signIn: true,

    reportCompleted: false,
    loginCompleted: false,
};

const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case actions.SET_MANY:
            return {
                ...state,
                ...action.payload,
            };
        case actions.SIGN_IN_TOGGLER:
            return {
                ...state,
                signIn: !state.signIn,
                loading: false,
                errorMessage: '',
                regSuccessMessage: '',
            };
        case actions.SIGN_IN_TOGGLER_AFTER_REG:
            return {
                ...state,
                signIn: !state.signIn,
                loading: false,
                errorMessage: '',
            };
        default:
            return state;
    }
};

export default authReducer;
