import axios from 'common/axios';
import {
    API_KEY,
    AUTH_URL,
    REFRESH_TOKEN_URL,
    SLUG_SIGN_IN,
    SLUG_SIGN_UP,
} from '../../common/axiosSettings';
import * as action from './actionTypes';
import { AUTHORIZATION } from 'common/constants';

export const openLoginScreen = () => {
    return {
        type: action.SET_MANY,
        payload: { openedLoginScreen: true },
    };
};

export const closeLoginScreen = () => {
    return {
        type: action.SET_MANY,
        payload: { openedLoginScreen: false },
    };
};

export const logout = (message) => {
    return (dispatch) => {
        localStorage.removeItem('token');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('userEmail');
        localStorage.removeItem('userID');
        localStorage.removeItem('expirationDate');
        localStorage.removeItem('name');
        localStorage.removeItem('surname');
        localStorage.removeItem('congregation');
        localStorage.removeItem('openedLoginScreen');
        dispatch(logoutNext(message));
        setTimeout(() => {
            dispatch(deleteLogoutMessage());
        }, 2500);
    };
};

export const logoutNext = (message) => {
    return {
        type: action.SET_MANY,
        payload: {
            token: '',
            refreshToken: '',
            userEmail: '',
            userID: '',
            expirationDate: '',
            authorization: [],
            name: '',
            surname: '',
            congregation: '',
            loading: false,
            logoutMessage: message,
            loginCompleted: false,
        },
    };
};

export const deleteLogoutMessage = () => {
    return {
        type: action.SET_MANY,
        payload: { logoutMessage: '' },
    };
};

export const setAuthTimeout = (expiresIn) => {
    return (dispatch) => {
        setTimeout(() => {
            dispatch(refreshAuth());
        }, expiresIn * 1000);
    };
};

export const authStart = () => {
    return {
        type: action.SET_MANY,
        payload: { loading: true },
    };
};

export const loginCompleted = () => {
    return {
        type: action.SET_MANY,
        payload: { loginCompleted: true },
    };
};

export const refreshAuth = () => {
    return (dispatch) => {
        const refreshToken = localStorage.getItem('refreshToken');
        if (!refreshToken) {
            dispatch(logout());
        } else {
            const refreshTokenData = `grant_type=refresh_token&refresh_token=${refreshToken}`;
            axios
                .post(REFRESH_TOKEN_URL, refreshTokenData)
                .then((response) => {
                    const newResponseData = {
                        expiresIn: response?.data?.expires_in,
                        idToken: response?.data?.id_token,
                        refreshToken: response?.data?.refresh_token,
                        email: localStorage.getItem('userEmail'),
                        localId: response?.data?.user_id,
                    };
                    if (
                        !newResponseData.expiresIn ||
                        !newResponseData.idToken ||
                        !newResponseData.refreshToken ||
                        !newResponseData.email ||
                        !newResponseData.localId
                    ) {
                        dispatch(logout());
                    } else {
                        dispatch(authSuccess(newResponseData, true));
                    }
                })
                .catch((error) => {
                    dispatch(authFail(error));
                });
        }
    };
};

export const authSuccess = (responseData) => {
    return (dispatch) => {
        const expirationDate = new Date(
            new Date().getTime() + responseData.expiresIn * 1000
        );
        localStorage.setItem('token', responseData.idToken);
        localStorage.setItem('refreshToken', responseData.refreshToken);
        localStorage.setItem('userEmail', responseData.email);
        localStorage.setItem('userID', responseData.localId);
        localStorage.setItem('expirationDate', expirationDate);
        const queryParams = `?auth=${responseData.idToken}&orderBy="userID"&equalTo="${responseData.localId}"`;
        axios.get(`users.json${queryParams}`).then((res) => {
            if (!Object.keys(res.data).length) {
                dispatch(logout());
                return;
            }
            const id = Object.keys(res.data)[0];

            const authorizationList = [];
            for (const authorization in res.data[id].authorization) {
                if (res.data[id].authorization[authorization]) {
                    authorizationList.push(authorization);
                }
            }

            localStorage.setItem('name', res.data[id].name);
            localStorage.setItem('surname', res.data[id].surname);
            localStorage.setItem('congregation', res.data[id].congregation);

            responseData.expirationDate = expirationDate;
            responseData.authorization = authorizationList;
            responseData.publisherID = res.data[id].publisherID;
            responseData.name = res.data[id].name;
            responseData.surname = res.data[id].surname;
            responseData.congregation = res.data[id].congregation;
            dispatch(setAuthTimeout(responseData.expiresIn));
            dispatch(closeLoginScreenTimeout());
            dispatch(authSuccessNext(responseData));
        });
    };
};

export const authSuccessNext = (responseData) => {
    return {
        type: action.SET_MANY,
        payload: {
            token: responseData.idToken,
            refreshToken: responseData.refreshToken,
            userEmail: responseData.email,
            userID: responseData.localId,
            expirationDate: responseData.expirationDate,
            authorization: responseData.authorization,
            name: responseData.name,
            surname: responseData.surname,
            congregation: responseData.congregation,
            loading: false,
            errorMessage: '',
            regSuccessMessage: '',
        },
    };
};

export const authFail = (error) => {
    let message = error?.response?.data?.error?.message;
    let errorMessage = '';
    if (message === 'EMAIL_EXISTS') {
        errorMessage = 'Účet s týmto emailom už existuje';
    } else if (message === 'TOO_MANY_ATTEMPTS_TRY_LATER') {
        errorMessage = 'Príliš veľa pokusov, skús to neskôr';
    } else if (message === 'EMAIL_NOT_FOUND') {
        errorMessage = 'Takýto účet neexistuje. Môžeš si ho vytvoriť';
    } else if (message === 'INVALID_EMAIL') {
        errorMessage = 'Zle zadaný email';
    } else if (message === 'INVALID_PASSWORD') {
        errorMessage = 'Zadal si zlé heslo';
    } else if (message === 'USER_DISABLED') {
        errorMessage = 'Účet bol zablokovaný';
    } else if (error?.message === 'Network Error') {
        errorMessage = 'Nie si pripojený k internetu';
    } else if (error?.message === 'INVALID_REFRESH_TOKEN') {
        errorMessage = 'Nesprávny refresh token';
    } else {
        errorMessage = `Chyba: ${message}`;
    }
    return {
        type: action.SET_MANY,
        payload: { errorMessage: errorMessage, loading: false },
    };
};

export const auth = (userData) => {
    return (dispatch) => {
        dispatch(authStart());
        const data = {
            email: userData.email,
            password: userData.password,
            returnSecureToken: true,
        };
        const URL_SLUG =
            userData.type === 'signIn' ? SLUG_SIGN_IN : SLUG_SIGN_UP;
        const url = `${AUTH_URL}${URL_SLUG}${API_KEY}`;
        axios
            .post(url, data)
            .then((response) => {
                if (userData.type === 'signIn') {
                    dispatch(authSuccess(response.data));
                }
                if (userData.type === 'signUp') {
                    dispatch(regSuccess(response.data, userData));
                }
            })
            .catch((error) => {
                dispatch(authFail(error));
            });
    };
};

export const regSuccess = (responseData, userData) => {
    return (dispatch, getState) => {
        const token = responseData.idToken;
        const userID = responseData.localId;
        const email = responseData.email;

        const data = {
            userID: userID,
            email: email,
            name: userData.name,
            surname: userData.surname,
            congregation: userData.congregation,
            authorization: '',
        };

        axios
            .get(`users/.json?auth=${token}`)
            .then((response) => {
                if (!response.data) {
                    data.authorization = {
                        [AUTHORIZATION.APPROVED]: true,
                        [AUTHORIZATION.ADMIN]: true,
                        [AUTHORIZATION.RESERVATION_WRITE]: true,
                        [AUTHORIZATION.RESERVATION_DELETE]: true,
                        [AUTHORIZATION.SERVICE_MEETINGS_WRITE]: true,
                        [AUTHORIZATION.MAINTENANCE_WRITE]: true,
                        [AUTHORIZATION.MAINTENANCE_EDIT]: true,
                        [AUTHORIZATION.MAINTENANCE_DELETE]: true,
                        [AUTHORIZATION.EDIT_USERS]: true,
                    };
                }
            })
            .then(() => {
                axios
                    .post(`users/.json?auth=${token}`, data)
                    .then(() => {
                        dispatch(afterRegSuccess());
                        dispatch(signInOpenerTimeout());
                    })
                    .catch((error) => {
                        dispatch(authFail(error));
                    });
            })
            .catch((error) => {
                console.log(error);
            });
    };
};

export const afterRegSuccess = () => {
    return {
        type: action.SET_MANY,
        payload: {
            loading: false,
            regSuccess: true,
            token: '',
            userID: '',
            userEmail: '',
            errorMessage: '',
            expirationDate: '',
            regSuccessMessage: 'Teraz sa môžeš prihlásiť',
        },
    };
};

export const switchRegSuccess = () => {
    return {
        type: action.SET_MANY,
        payload: {
            regSuccess: true,
            loading: false,
        },
    };
};

export const switchToSigningInTimeout = () => {
    return (dispatch) => {
        setTimeout(() => {
            dispatch(signInTogglerAfterReg());
            dispatch(switchRegSuccess());
        }, 700);
    };
};

export const signInToggler = () => {
    return {
        type: action.SIGN_IN_TOGGLER,
    };
};
export const signInTogglerAfterReg = () => {
    return {
        type: action.SIGN_IN_TOGGLER_AFTER_REG,
    };
};

export const signInOpenerTimeout = () => {
    return (dispatch) => {
        setTimeout(() => {
            dispatch(signInOpener());
        }, 1000);
    };
};

export const signInOpener = () => {
    return {
        type: action.SET_MANY,
        payload: { signIn: true, regSuccess: false },
    };
};

export const closeLoginScreenTimeout = () => {
    return (dispatch) => {
        setTimeout(() => {
            dispatch(closeLoginScreen());
            dispatch(loginCompleted());
        }, 200);
    };
};

export const postRegAuth = (userData) => {};
