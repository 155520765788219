import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useReactToPrint } from 'react-to-print';
import styled from 'styled-components';

import useFetch from 'hooks/useFetch';
import * as actionCreators from 'store/actions';
import { genericSort } from 'common/utils';
import Button from 'components/UI/Button';
import { Row, Box } from 'components/BasicComponents';
import { confirmModalAsync } from 'components/UI/ConfirmModal';
import Loading from 'components/UI/Loading';

import ServiceMeetingsEdit from './ServiceMeetingsEdit';
import ServiceMeeting from './ServiceMeeting';
import { AUTHORIZATION } from 'common/constants';

const processServiceMeetings = (data) => {
    const newData = [];
    Object.keys(data).forEach((id) => {
        newData.push({
            day: data[id].day,
            time: data[id].time,
            name: data[id].name,
            room: data[id].room,
            id: id,
        });
    });
    return newData;
};

const ServiceMeetings = (props) => {
    const { t } = useTranslation();

    const [isPrinting, setIsPrinting] = useState(false);

    const [serviceMeeting, setServiceMeeting] = useState({
        day: 1,
        time: '',
        name: '',
        room: '01',
    });

    const [message, setMessage] = useState('');
    const [serviceMeetings, setServiceMeetings] = useState([]);
    const [editable, setEditable] = useState('');

    const { data, loading, sendRequest } = useFetch();

    const token = props.auth.token;

    const canEdit =
        token &&
        props.auth.authorization?.includes(
            AUTHORIZATION.SERVICE_MEETINGS_WRITE
        );

    const changeHandler = (e) => {
        const newMeeting = { ...serviceMeeting };
        newMeeting[e.target.name] = e.target.value;
        setServiceMeeting(newMeeting);
    };

    const loadServiceMeetings = useCallback(
        () =>
            sendRequest({
                slug: 'serviceMeetings',
                method: 'get',
                token,
                processData: processServiceMeetings,
            }),
        [sendRequest, token]
    );

    const postServiceMeetings = (data) =>
        sendRequest({
            slug: 'serviceMeetings',
            method: 'post',
            token,
            data,
            onCompleted: loadServiceMeetings,
        });

    const patchServiceMeetings = (id, data) =>
        sendRequest({
            slug: `serviceMeetings/${id}`,
            method: 'patch',
            token,
            data,
            onCompleted: loadServiceMeetings,
        });

    const deleteServiceMeetings = (id, onCompleted) =>
        sendRequest({
            slug: `serviceMeetings/${id}`,
            method: 'delete',
            token,
            onCompleted,
        });

    useEffect(() => {
        setServiceMeetings(data);
    }, [data]);

    useEffect(() => {
        loadServiceMeetings();
    }, [loadServiceMeetings]);

    const postHandler = () => {
        setMessage('');
        postServiceMeetings(serviceMeeting);
        setServiceMeeting({
            day: 1,
            time: '',
            name: '',
            room: '01',
        });
    };

    const beforeRemoveHandler = async (serviceMeeting) => {
        const confirmResult = await confirmModalAsync({
            props: props,
            title: t('Odstranit schůzku'),
            question: t('Opravdu odstranit schůzku?'),
        });

        if (confirmResult) {
            removeHandler(serviceMeeting);
        }
    };

    const removeHandler = (serviceMeeting) => {
        const newServiceMeetings = serviceMeetings.filter(
            (item) => item.id !== serviceMeeting.id
        );
        deleteServiceMeetings(
            serviceMeeting.id,
            setServiceMeetings(newServiceMeetings)
        );
    };

    const serviceMeetingEdit = (e, id) => {
        const wantedIndex = serviceMeetings.findIndex((row) => row.id === id);
        const newServiceMeetings = [...serviceMeetings];

        newServiceMeetings[wantedIndex][e.target.name] = e.target.value;

        setServiceMeetings(newServiceMeetings);
    };

    const postEditedServiceMeeting = (id) => {
        const data = serviceMeetings.find(
            (serviceMeeting) => serviceMeeting.id === id
        );
        patchServiceMeetings(id, data);
        setEditable('');
    };

    const componentRef = useRef();
    const doPrint = useReactToPrint({
        content: () => componentRef.current,
    });
    const handlePrint = () => {
        setIsPrinting(true);
    };

    useEffect(() => {
        if (isPrinting) {
            doPrint();
            setTimeout(() => setIsPrinting(false), 100);
        }
    }, [isPrinting, doPrint]);

    const sortedByDate = genericSort(serviceMeetings, false, 'num', 'day');

    const serviceMeetingsList = [];
    sortedByDate.forEach((serviceMeeting) => {
        serviceMeetingsList.push(
            <ServiceMeeting
                data={serviceMeeting}
                key={serviceMeeting.id}
                isEditable={editable === serviceMeeting.id}
                edit={(e) => serviceMeetingEdit(e, serviceMeeting.id)}
                blueButton={
                    editable === serviceMeeting.id
                        ? () => postEditedServiceMeeting(serviceMeeting.id)
                        : () => setEditable(serviceMeeting.id)
                }
                redButton={
                    editable === serviceMeeting.id
                        ? () => setEditable(false)
                        : () => beforeRemoveHandler(serviceMeeting)
                }
                isPrinting={isPrinting}
            />
        );
    });

    return (
        <div>
            <Routes>
                <Route
                    path="edit"
                    element={
                        canEdit ? (
                            <ServiceMeetingsEdit
                                key="serviceMeetingsEdit"
                                change={changeHandler}
                                postHandler={postHandler}
                                data={serviceMeeting}
                                message={message}
                            />
                        ) : (
                            <Navigate to="/serviceMeetings" />
                        )
                    }
                />
            </Routes>
            <ButtonsRowStyled>
                <Button
                    text={t('Vytisknout')}
                    type={'purpleFull'}
                    icon={'print'}
                    onClick={handlePrint}
                />
            </ButtonsRowStyled>
            <Box ref={componentRef}>
                <h1>{t('Rozpis schůzek')}</h1>

                {!!serviceMeetings.length && <ServiceMeeting isLabel />}
                {!!loading && <Loading />}
                {serviceMeetingsList}
            </Box>
        </div>
    );
};
const mapStateToProps = (state) => {
    return {
        auth: state.auth,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        openModal: (component, componentProps, title) =>
            dispatch(
                actionCreators.openModal(component, componentProps, title)
            ),
        closeModal: () => dispatch(actionCreators.closeModal()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ServiceMeetings);

const ButtonsRowStyled = styled(Row)`
    max-width: 90rem;
    justify-content: space-between;
`;
