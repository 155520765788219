export const SET_MANY = 'SET_MANY';
export const SIGN_IN_TOGGLER = 'SIGN_IN_TOGGLER';
export const SIGN_IN_TOGGLER_AFTER_REG = 'SIGN_IN_TOGGLER_AFTER_REG';

export const OPEN_MODAL = 'OPEN_MODAL';
export const CLOSE_MODAL = 'CLOSE_MODAL';
export const START_PROCESSING = 'START_PROCESSING';
export const STOP_PROCESSING = 'STOP_PROCESSING';

export const SET_FLASH_MESSAGE = 'SET_FLASH_MESSAGE';
export const RESET_FLASH_MESSAGE = 'RESET_FLASH_MESSAGE';
