import React from 'react';
import ReactDOM from 'react-dom';
import { createStore, combineReducers, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import './i18n';

import reportWebVitals from './reportWebVitals';
import authReducer from 'store/reducers/auth';
import modalsReducer from 'store/reducers/modals';

import App from './App';
import 'index.css';

// if ('serviceWorker' in navigator) {
//   navigator.serviceWorker.register('/sw.js').then(() => {});
// }

const rootReducer = combineReducers({
    auth: authReducer,
    modals: modalsReducer,
});

const store = createStore(rootReducer, applyMiddleware(thunk));

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <App />
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
);

reportWebVitals();
