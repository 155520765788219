import React from 'react';
import styled from 'styled-components';

import { Box, Row } from '../BasicComponents';

const duplicateInputColors = {
    2: { background: 'rgba(233, 186, 83, 0.7)', color: 'rgb(71, 50, 5)' },
    3: { background: 'rgba(250, 116, 54, 0.7)', color: 'rgb(68, 23, 2)' },
    4: { background: 'rgba(247, 16, 16, 0.7)', color: 'rgb(44, 3, 3)' },
};

const Input = ({
    label,
    errorAllowed,
    error,
    duplicateNum,
    children,
    id,
    mini,
    onChange,
    ...props
}) => {
    let duplicateNumNew = duplicateNum;
    if (duplicateNum === 1) {
        duplicateNumNew = null;
    } else if (duplicateNum > 4) {
        duplicateNumNew = 4;
    }
    const defaultBg = props?.background
        ? props.background
        : props?.data?.background
        ? `${props.data.background}20`
        : 'transparent';

    const defaultColor = props?.data?.dataColor || '#000';

    const renderedBg = duplicateNumNew
        ? duplicateInputColors[duplicateNumNew].background
        : defaultBg;

    const renderedColor = duplicateNumNew
        ? duplicateInputColors[duplicateNumNew].color
        : defaultColor;

    const renderedInput =
        props?.type === 'select' ? (
            <select id={id} onChange={onChange} {...props}>
                {children}
            </select>
        ) : (
            <input id={id} onChange={onChange} {...props} />
        );

    return (
        <InputWrapperStyled width={props?.width}>
            <LabelWrapperStyled
                background={renderedBg}
                color={renderedColor}
                mini={mini}
                {...props}
            >
                {renderedInput}
                {label && <label htmlFor={id}>{label}</label>}
            </LabelWrapperStyled>
            {errorAllowed && <ErrorMessageStyled>{error}</ErrorMessageStyled>}
        </InputWrapperStyled>
    );
};

export default Input;

const InputWrapperStyled = styled(Box)`
    width: ${(props) => props?.width || 'auto'};
`;
const LabelWrapperStyled = styled(Row)`
    align-items: center;
    & label {
        padding: 0.5rem;
    }

    & input:-webkit-autofill,
    & input:-webkit-autofill:hover,
    & input:-webkit-autofill:focus,
    & input:-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0 30px white inset !important;
    }

    & input,
    & select {
        width: ${(props) => props?.width || 'auto'};
        height: ${(props) => props?.height || '4rem'};
        background: ${(props) => props.background};
        color: ${(props) => props?.color};
        font-size: ${(props) => `${props?.data?.fontSize}rem` || '1.3rem'};
        font-weight: ${(props) => `${props?.data?.fontWeight}` || '400'};
        font-family: 'DMSans', calibri, tahoma;
        text-transform: ${(props) => (props.uppercase ? 'uppercase' : 'none')};
        outline: none;
        border: 0;
        border-bottom: ${(props) =>
            props?.noBorder ? '' : '1px solid rgba(128, 128, 128, 0.2)'};
        border-radius: ${(props) => props?.borderRadius};
        padding: ${(props) => (props.mini ? '.5rem' : '.5rem 1rem')};
        margin: ${(props) => props.margin || '0.5rem'};
        text-align: ${(props) => props?.textAlign || 'center'};
        transition: all 0.5s;

        &::placeholder {
            color: rgba(0, 0, 0, 0.4);
            font-size: ${(props) => (props.mini ? '1rem' : '')};
        }
        &:active::placeholder,
        &:focus::placeholder {
            color: transparent;
        }
        &:hover,
        &:active,
        &:focus {
            border-bottom: ${(props) =>
                props?.noBorder ? '' : '1px solid  rgb(50, 61, 115)'};
        }
        &:disabled {
            cursor: not-allowed;
            opacity: 0.4;
            border-bottom: ${(props) =>
                props?.noBorder ? '' : '1px solid rgba(128, 128, 128, 0.2)'};
        }
    }
    & input[type='radio'] {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        border-radius: 50%;
        width: 3rem;
        height: 3rem;
        min-width: 3rem;
        min-height: 3rem;
        border: 2px solid rgb(197, 197, 197);
        transition: 0.3s all linear;
        outline: none;
        margin: 0 7px;
        padding: 0;

        &:checked {
            border: 1.5rem solid #1fca76;
        }
    }
`;
const ErrorMessageStyled = styled(Row)`
    width: 100%;
    color: red;
    padding: 5px;
    align-items: center;
`;
