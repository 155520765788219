import * as action from './actionTypes';

export const openModal = (component, componentProps, title = null) => {
    return {
        type: action.OPEN_MODAL,
        component,
        componentProps,
        title,
    };
};

export const closeModal = () => {
    return {
        type: action.CLOSE_MODAL,
    };
};

export const startProcessing = () => {
    return {
        type: action.START_PROCESSING,
    };
};

export const stopProcessing = () => {
    return {
        type: action.STOP_PROCESSING,
    };
};

export const setFlash = (values) => {
    return {
        type: action.SET_FLASH_MESSAGE,
        ...values.variables,
    };
};

export const resetFlash = () => {
    return {
        type: action.RESET_FLASH_MESSAGE,
    };
};
