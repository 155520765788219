import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { AUTHORIZATION } from 'common/constants';
import { Icons } from 'common/Icons';
import { mediaQueries } from 'common/theme';

import { Row, MessageBox } from 'components/BasicComponents';
import Input from 'components/UI/Input';
import Button from 'components/UI/Button';
import { SettingsSectionsWrapper, SettingsSection } from './Section';

const User = ({
    isEditable,
    data,
    edit,
    index,
    blueButton,
    redButton,
    errorMessage,
    isLabel,
}) => {
    const { t } = useTranslation();

    if (isLabel) {
        return (
            <UserStyled isLabel>
                <UserColumnStyled className="num">#</UserColumnStyled>
                <UserColumnStyled className="name">
                    {t('Meno a priezvisko')}
                </UserColumnStyled>
                <UserColumnStyled className="id">{t('ID')}</UserColumnStyled>
                <UserColumnStyled className="status">
                    {t('Stav účtu')}
                </UserColumnStyled>
                <UserColumnStyled className="email">
                    {t('e-mail')}
                </UserColumnStyled>
                <UserColumnStyled className="email">
                    {t('sbor')}
                </UserColumnStyled>
            </UserStyled>
        );
    }

    const settings = [
        {
            section: 'authorization',
            id: AUTHORIZATION.ADMIN,
            label: t('Admin'),
        },
        {
            section: 'authorization',
            id: AUTHORIZATION.RESERVATION_WRITE,
            label: t('Rezervační systém - zápis'),
        },
        {
            section: 'authorization',
            id: AUTHORIZATION.RESERVATION_DELETE,
            label: t('Rezervační systém - mazání'),
        },
        {
            section: 'authorization',
            id: AUTHORIZATION.SERVICE_MEETINGS_WRITE,
            label: t('Schůzky - zápis'),
        },
        {
            section: 'authorization',
            id: AUTHORIZATION.MAINTENANCE_WRITE,
            label: t('Údržba - zápis'),
        },
        {
            section: 'authorization',
            id: AUTHORIZATION.MAINTENANCE_EDIT,
            label: t('Údržba - detaily'),
        },
        {
            section: 'authorization',
            id: AUTHORIZATION.MAINTENANCE_DELETE,
            label: t('Údržba - mazání'),
        },
        {
            section: 'authorization',
            id: AUTHORIZATION.EDIT_USERS,
            label: t('Správa uživatelů'),
        },
    ];

    const settingsSection = settings.map((itm) => (
        <SectionSettingStyled key={itm.id}>
            <Input
                id={itm.id}
                type="checkbox"
                name={itm.section}
                checked={data?.[itm.section]?.[itm.id]}
                onChange={(e) => {
                    edit(e);
                }}
                label={itm.label}
                height="2rem"
            />
        </SectionSettingStyled>
    ));

    const isApproved = data?.authorization && data?.authorization?.approved;

    return (
        <UserStyled isEditable={isEditable}>
            <UserColumnStyled className="num">{index + 1}</UserColumnStyled>
            <UserColumnStyled isEditable={isEditable} className="name">
                {isEditable ? (
                    <>
                        <Input
                            key="name"
                            type="text"
                            onChange={
                                isEditable
                                    ? (e) => {
                                          edit(e);
                                      }
                                    : null
                            }
                            name="name"
                            value={data?.name}
                            placeholder={t('Meno')}
                            height="3rem"
                            width="14rem"
                            textAlign="left"
                        />
                        <Input
                            key="surname"
                            type="text"
                            onChange={
                                isEditable
                                    ? (e) => {
                                          edit(e);
                                      }
                                    : null
                            }
                            name="surname"
                            value={data?.surname}
                            placeholder={t('Priezvisko')}
                            height="3rem"
                            width="14rem"
                            textAlign="left"
                        />
                    </>
                ) : (
                    `${data?.surname} ${data?.name}`
                )}
            </UserColumnStyled>
            <UserColumnStyled className="status">
                {isEditable ? (
                    <Input
                        type="select"
                        onChange={(e) => {
                            edit(e);
                        }}
                        name="approved"
                        value={data?.authorization?.approved ? 'approved' : ''}
                        height="3rem"
                    >
                        <option value="">{t('Neschválený')}</option>
                        <option value="approved">{t('Schválený')}</option>
                    </Input>
                ) : (
                    <AuthBox isApproved={isApproved}>
                        <i
                            className={Icons[isApproved ? 'success' : 'cancel']}
                        />
                        {isApproved ? t('Schválený') : t('Neschválený')}
                    </AuthBox>
                )}
            </UserColumnStyled>
            <UserColumnStyled className="email">{data?.email}</UserColumnStyled>
            {isEditable && (
                <SettingsSectionsWrapper>
                    <SettingsSection
                        type={'user'}
                        heading={t('Oprávnení uživatele')}
                        data={settingsSection}
                    />
                </SettingsSectionsWrapper>
            )}
            <>
                {isEditable && errorMessage && (
                    <MessageBox>{errorMessage}</MessageBox>
                )}
                <Button
                    text={isEditable ? t('Uložiť') : t('Upraviť')}
                    type="blueFull"
                    onClick={blueButton}
                    icon={isEditable ? 'save' : 'edit'}
                />
                <Button
                    text={isEditable ? t('Zrušiť') : t('Odstrániť')}
                    type="redFull"
                    onClick={redButton}
                    icon={isEditable ? 'cancel' : 'delete'}
                />
            </>
        </UserStyled>
    );
};

export default User;

const UserStyled = styled(Row)`
    background: ${(props) =>
        props.isEditable ? 'rgba(218, 218, 218, 0.2)' : ''};
    width: 100%;
    max-width: 900px;
    min-height: 3rem;
    flex-wrap: wrap;
    align-items: center;
    border-bottom: 1px solid rgba(214, 214, 214, 0.5);
    font-size: 1.3rem;
    padding: 0.5rem;
    font-weight: ${(props) => (props.isLabel ? '800 !important' : '')};
    &:last-of-type {
        border-bottom: 0;
    }
    &:hover {
        background: ${(props) =>
            props.isLabel ? '' : 'rgba(161, 186, 202, 0.09)'};
    }
`;
const UserColumnStyled = styled(Row)`
    padding: 0px 0.5rem;
    justify-content: center;
    align-items: center;
    &.num {
        width: 3rem;
    }
    &.name {
        min-width: 15rem;
        max-width: ${(props) => (props.isEditable ? '' : '15rem')};
        justify-content: flex-start;
        font-weight: 600;
    }
    &.ltr {
        min-width: 5rem;
        font-size: 1.4rem;
        font-weight: 800;
        padding: 0 0.5rem;
        @media ${mediaQueries.m} {
            width: 100%;
        }
    }
    &.id,
    &.icon {
        width: 4rem;
    }
    &.icon {
        color: rgb(21, 131, 80);
    }
    &.status {
        width: 12rem;
    }
    &.email {
        font-size: 1.2rem;
        width: 22rem;
        overflow-x: scroll;
        justify-content: flex-start;
        -ms-overflow-style: none;
        scrollbar-width: none;
        &::-webkit-scrollbar {
            display: none;
        }
    }
`;
const AuthBox = styled(Row)`
    background: ${(props) =>
        props.isApproved ? 'rgba(21, 131, 80,.07)' : 'rgba(182, 0, 0,.07)'};
    color: ${(props) =>
        props.isApproved ? 'rgb(21, 131, 80)' : 'rgb(182, 0, 0)'};
    font-weight: 800;
    padding: 0.5rem;
    align-items: center;
    & i {
        margin-right: 0.5rem;
    }
`;

const SectionSettingStyled = styled(Row)`
    border-radius: ${(props) => props.theme.radius.xs};
    justify-content: center;
    align-items: center;
    padding: 2px 7px;
    margin: 1px 3px;
    border: 1px solid transparent;
    &:hover {
        background: rgba(0, 0, 0, 0.04);
    }
`;
