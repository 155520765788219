import React from 'react';
import styled from 'styled-components';

import { mediaQueries } from 'common/theme';
import { Box } from 'components/BasicComponents';
import Menu from '../Menu';
import Backdrop from './Backdrop';
import HamburgerIcon from './HamburgerIcon';

const SideDrawer = ({ onClick, openedSideDrawer }) => (
    <WrapperStyled>
        <Backdrop onClick={onClick} isOpened={openedSideDrawer} />
        <SideDrawerStyled onClick={onClick} isOpened={openedSideDrawer}>
            <HamburgerIcon inSideDrawer />
            <Menu />
        </SideDrawerStyled>
    </WrapperStyled>
);

export default SideDrawer;

const SideDrawerStyled = styled(Box)`
    width: 20rem;
    height: 100%;
    background: #fff;
    position: fixed;
    z-index: ${(props) => props.theme.zIndex.sideDrawer};
    padding-top: 5px;
    padding-right: 10px;
    transition: transform 0.1s ease-in-out;
    transform: translateX(${(props) => (props.isOpened ? '0%' : '-100%')});
    @media ${mediaQueries.mMin} {
        display: none;
    }
`;

const WrapperStyled = styled.div`
    @media ${mediaQueries.mMin} {
        display: none;
    }
`;
