import React from 'react';
import { connect } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { AUTHORIZATION } from 'common/constants';
import { Icons } from 'common/Icons';
import { VERSION } from 'pages/myAccount/Help';
import { Box, Row } from './BasicComponents';

const resetScroll = () => {
    document.documentElement.scrollTop = 0;
    document.body.scrollTop = 0;
};

const Menu = ({ auth }) => {
    const { t } = useTranslation();

    const isLoggedIn = !!auth?.token;
    const isAuthorized = !!(
        isLoggedIn && auth.authorization?.includes(AUTHORIZATION.APPROVED)
    );
    const isAdmin = !!(
        isAuthorized && auth?.authorization.includes(AUTHORIZATION.ADMIN)
    );

    const canEditUsers = auth?.authorization.includes(AUTHORIZATION.EDIT_USERS);

    const canReservationWrite = !!(
        isAuthorized &&
        auth.authorization?.includes(AUTHORIZATION.RESERVATION_WRITE)
    );
    const canReservationDelete = !!(
        isAuthorized &&
        auth.authorization?.includes(AUTHORIZATION.RESERVATION_DELETE)
    );
    const canServiceMeetingsWrite = !!(
        isAuthorized &&
        auth.authorization?.includes(AUTHORIZATION.SERVICE_MEETINGS_WRITE)
    );
    const canMaintenanceWrite = !!(
        isAuthorized &&
        auth.authorization?.includes(AUTHORIZATION.MAINTENANCE_WRITE)
    );
    const canMaintenanceEdit = !!(
        isAuthorized &&
        auth.authorization?.includes(AUTHORIZATION.MAINTENANCE_EDIT)
    );
    const canMaintenanceDelete = !!(
        isAuthorized &&
        auth.authorization?.includes(AUTHORIZATION.MAINTENANCE_DELETE)
    );

    const menuSections = [
        {
            sectionName: 'administration',
            sectionHeading: t('Administrace'),
            icon: 'administration',
            menuItems: [],
        },
        {
            sectionName: 'my-account',
            sectionHeading: t('Můj účet'),
            icon: 'user',
            menuItems: [
                {
                    linkTo: '/my-account/settings',
                    icon: 'usersettings',
                    text: t('NASTAVENÍ ÚČTU'),
                },
            ],
        },
        {
            sectionName: 'admin',
            sectionHeading: t('Admin'),
            icon: 'speaker',
            menuItems: [],
        },
    ];

    const NavLinkC = ({ icon, text, to }) => (
        <NavLink
            onClick={resetScroll}
            className={({ isActive }) => [isActive && 'activeLink']}
            exact="exact"
            to={to}
        >
            <i className={Icons[icon]} />
            {text}
        </NavLink>
    );

    const renderSection = (sectionName) => {
        const selectedSection = menuSections.find(
            (s) => s.sectionName === sectionName
        );

        return (
            <>
                <p>
                    <i className={Icons[selectedSection.icon]} />
                    {selectedSection.sectionHeading}
                </p>
                {selectedSection.menuItems.map((menuItem, i) => (
                    <NavLinkC
                        key={`${menuItem.text}${i}`}
                        to={menuItem.linkTo}
                        icon={menuItem.icon}
                        text={menuItem.text}
                    />
                ))}
            </>
        );
    };

    const myAccount = <Box key="my-account">{renderSection('my-account')}</Box>;

    return (
        <MenuStyled>
            {(!isLoggedIn || (isLoggedIn && !auth.authorization.length)) && (
                <MenuMessageStyled>
                    <i className={Icons['info']}></i>
                    {isLoggedIn
                        ? t(
                              'Zatím máš jen omezený přístup, protože tvůj účet ještě nebyl schválen'
                          )
                        : t('Pro víc možností se musíš přihlásit')}
                </MenuMessageStyled>
            )}

            {isAuthorized && (
                <>
                    <NavLinkC
                        icon={'calendar'}
                        text={t('Rezervační systém')}
                        to="/reservations"
                    />
                    <NavLinkC
                        icon={'bag'}
                        text={t('Schůzky před službou')}
                        to="/service-meetings"
                    />
                    <NavLinkC
                        icon={'settings'}
                        text={t('Údržba')}
                        to="/maintenance"
                    />
                </>
            )}

            {isAuthorized &&
                (canReservationWrite ||
                    canReservationDelete ||
                    canServiceMeetingsWrite ||
                    canMaintenanceWrite ||
                    canMaintenanceEdit ||
                    canMaintenanceDelete ||
                    isAdmin) && (
                    <p>
                        <i className={Icons['administration']}></i>
                        {t('Administrace')}
                    </p>
                )}
            {isAuthorized && (canReservationWrite || canReservationDelete) && (
                <NavLinkC
                    icon={'calendar'}
                    text={t('UPRAVIT REZERVAČNÍ')}
                    to="/reservations/edit"
                />
            )}
            {isAuthorized && canServiceMeetingsWrite && (
                <NavLinkC
                    icon={'bag'}
                    text={t('UPRAVIT SCHŮZKY')}
                    to="/service-meetings/edit"
                />
            )}
            {isAuthorized &&
                (canMaintenanceWrite ||
                    canMaintenanceEdit ||
                    canMaintenanceDelete) && (
                    <NavLinkC
                        icon={'settings'}
                        text={t('UPRAVIT ÚDRŽBU')}
                        to="/maintenance/edit"
                    />
                )}

            {isAuthorized && myAccount}

            {isAuthorized && (isAdmin || canEditUsers) && (
                <p>
                    <i className={Icons['settings']}></i>
                    {t('Admin')}
                </p>
            )}
            {isAuthorized && (isAdmin || canEditUsers) && (
                <NavLinkC
                    icon={'users'}
                    text={t('UŽIVATELÉ')}
                    to="/admin/users"
                />
            )}
            {isAuthorized && isAdmin && (
                <NavLinkC
                    icon={'settings'}
                    text={t('NASTAVENÍ SBORU')}
                    to="/admin/kh-settings"
                />
            )}

            <MenuVersionStyled aria-disabled="true">
                <Link to="/my-account/help" onClick={resetScroll}>
                    {VERSION}
                </Link>
            </MenuVersionStyled>
        </MenuStyled>
    );
};

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
    };
};

export default connect(mapStateToProps)(Menu);

const MenuStyled = styled.nav`
    width: 190px;
    padding: 7px 0 15px 0;
    overflow-y: scroll;
    overflow-x: hidden;
    -ms-overflow-style: none;
    scrollbar-width: none;
    text-transform: uppercase;
    display: flex;
    flex-direction: column;

    &::-webkit-scrollbar {
        display: none;
    }

    & a {
        width: 100%;
        min-height: 30px;
        line-height: 30px;
        text-decoration: none;
        color: ${(props) => props.theme.colors.black6};
        font-size: 1.2rem;
        display: block;
        padding-left: 10px;
        transition: color 0.3s;
        font-weight: 600;
    }

    & a i {
        width: 1.4rem;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        margin-right: 7px;
        transition: transform 0.4s;
    }
    & a:hover i {
        transform: scale(1.3);
    }
    & a:hover,
    & a:focus,
    & a.activeLink {
        color: ${(props) => props.theme.colors.label};
        background: #eaefef;
        outline: none;
    }
    & a.activeLink {
        font-weight: 800;
    }
    & a.activeLink i {
        transform: scale(1.3);
    }

    & p {
        width: 100%;
        color: #fff;
        background: ${(props) => `${props.theme.colors.label}`};
        font-weight: 800;
        font-size: 1.2rem;
        margin: 10px 0 5px 0;
        padding: 5px 10px;
        & i {
            width: 1.4rem;
            margin-right: 7px;
        }
    }
`;

const MenuMessageStyled = styled.div`
    padding: 5px 10px;
    margin: 5px;
    font-size: 1.2rem;
    border-radius: ${(props) => props.theme.radius.s};
    background: ${(props) => props.theme.colors.warningLight};
    color: #000;
    & i {
        margin-right: 0.7rem;
    }
`;

const MenuVersionStyled = styled(Row)`
    justify-content: flex-end;
    & a {
        font-size: 1rem;
        color: ${(props) => props.theme.colors.black4};
        width: auto;
    }
    & a:hover {
        color: #b40a0a;
        background: transparent;
    }
`;
