import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { mediaQueries } from 'common/theme';
import { Box, Row, MessageBox } from 'components/BasicComponents';
import Input from 'components/UI/Input';
import Button from 'components/UI/Button';

const MaintenanceEdit = ({ data, change, message, postHandler }) => {
    const { t } = useTranslation();
    const { task, name, endDate } = data;
    return (
        <BoxStyled>
            <h1>{t('Přidat novou úlohu')}</h1>
            <InputsWrapperStyled>
                <Box>
                    <Row>
                        <Input
                            type="text"
                            value={task}
                            name="task"
                            onChange={change}
                            placeholder="Závada"
                        />
                    </Row>

                    <Row>
                        <Input
                            type="text"
                            value={name}
                            name="name"
                            onChange={change}
                            placeholder="Kdo provede"
                        />
                    </Row>
                    <Row>
                        <Input
                            type="date"
                            value={endDate}
                            name="endDate"
                            onChange={change}
                        />
                    </Row>
                </Box>
            </InputsWrapperStyled>
            {!!message && <MessageBox>{message}</MessageBox>}
            <Button
                text={t('Přidat úlohu')}
                type="greenFull"
                onClick={postHandler}
                icon="plus"
                my="1rem"
            />
        </BoxStyled>
    );
};

export default MaintenanceEdit;

const BoxStyled = styled(Box)`
    margin-bottom: 30px;
`;
const InputsWrapperStyled = styled(Row)`
    width: 90%;
    justify-content: space-between;
    align-items: center;

    @media ${mediaQueries.m} {
        flex-direction: column;
    }
`;
