import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { mediaQueries } from 'common/theme';
import { Row, Box, MessageBox } from 'components/BasicComponents';
import { DAYS } from 'common/constants';
import Button from 'components/UI/Button';
import Input from 'components/UI/Input';

export const rooms = [
    {
        id: '01',
        name: 'Hlavní sál',
    },
    {
        id: '02',
        name: 'Malý sál',
    },
    {
        id: '03',
        name: 'Zasedačka',
    },
];

const ServiceMeetingsEdit = ({ data, change, message, postHandler }) => {
    const { t } = useTranslation();
    const { day, time, name, room } = data;

    return (
        <BoxStyled>
            <h1>{t('Přidat novou schůzku')}</h1>
            <InputsWrapperStyled>
                <Row>
                    <Input
                        type="select"
                        value={day}
                        name="day"
                        onChange={change}
                    >
                        {Object.keys(DAYS).map((day, i) => (
                            <option key={`${day}_${i}`} value={day}>
                                {DAYS[day]}
                            </option>
                        ))}
                    </Input>
                </Row>
                <Row>
                    <Input
                        type="time"
                        value={time}
                        name="time"
                        onChange={change}
                    />
                    <Input
                        type="text"
                        value={name}
                        name="name"
                        onChange={change}
                    />
                    <Input
                        type="select"
                        value={room}
                        name="room"
                        onChange={change}
                    >
                        {rooms.map((room, i) => (
                            <option key={`${room.id}_${i}`} value={room.id}>
                                {room.name}
                            </option>
                        ))}
                    </Input>
                </Row>
            </InputsWrapperStyled>
            {!!message && <MessageBox>{message}</MessageBox>}
            <Button
                text={t('Přidat schůzku')}
                type="greenFull"
                onClick={postHandler}
                icon="plus"
                my="1rem"
            />
        </BoxStyled>
    );
};

export default ServiceMeetingsEdit;

const BoxStyled = styled(Box)`
    margin-bottom: 30px;
`;
const InputsWrapperStyled = styled(Box)`
    width: 90%;
    justify-content: space-between;
    align-items: center;

    @media ${mediaQueries.m} {
        flex-direction: column;
    }
`;
