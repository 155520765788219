import React, { useState, useCallback, useEffect } from 'react';
import { Route, Navigate, Routes } from 'react-router-dom';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { mediaQueries } from 'common/theme';
import { AUTHORIZATION } from 'common/constants';
import { Box } from 'components/BasicComponents';
import { Icons } from 'common/Icons';

import Reservation from 'pages/administration/Reservation/Reservations';
import ServiceMeetings from 'pages/administration/ServiceMeetings/ServiceMeetings';
import Maintenances from 'pages/administration/Maintenance/Maintenances';

import Settings from 'pages/myAccount/Settings/Settings';
import Help from 'pages/myAccount/Help';

import Users from 'pages/admin/Users/Users';
import KHSettings from 'pages/admin/KHSettings/KHSettings';

const ContentRight = ({ auth }) => {
    const [topButtonVisible, setTopButtonVisible] = useState(false);

    const isLoggedIn = !!auth?.token;

    const isAuthorized = !!(
        isLoggedIn && auth.authorization.includes(AUTHORIZATION.APPROVED)
    );
    const isAdmin = !!(
        isAuthorized && auth?.authorization.includes(AUTHORIZATION.ADMIN)
    );

    const canEditUsers = auth?.authorization.includes(AUTHORIZATION.EDIT_USERS);

    const canReservationWrite = !!(
        isAuthorized &&
        auth.authorization?.includes(AUTHORIZATION.RESERVATION_WRITE)
    );
    const canReservationDelete = !!(
        isAuthorized &&
        auth.authorization?.includes(AUTHORIZATION.RESERVATION_DELETE)
    );
    const canServiceMeetingsWrite = !!(
        isAuthorized &&
        auth.authorization?.includes(AUTHORIZATION.SERVICE_MEETINGS_WRITE)
    );
    const canMaintenanceWrite = !!(
        isAuthorized &&
        auth.authorization?.includes(AUTHORIZATION.MAINTENANCE_WRITE)
    );
    const canMaintenanceEdit = !!(
        isAuthorized &&
        auth.authorization?.includes(AUTHORIZATION.MAINTENANCE_EDIT)
    );
    const canMaintenanceDelete = !!(
        isAuthorized &&
        auth.authorization?.includes(AUTHORIZATION.MAINTENANCE_DELETE)
    );

    const canViewMaintenance =
        canMaintenanceWrite || canMaintenanceEdit || canMaintenanceDelete;

    const resetScroll = () => {
        document.documentElement.scrollTop = 0;
        document.body.scrollTop = 0;
    };

    const clickToTopHandler = useCallback(() => {
        const currentPosition = window.scrollY;
        if (currentPosition > 1000) {
            setTopButtonVisible(true);
        } else {
            setTopButtonVisible(false);
        }
    }, []);

    useEffect(() => {
        window.addEventListener('scroll', clickToTopHandler, {
            passive: false,
        });
        return () => {
            window.removeEventListener('scroll', clickToTopHandler, {
                passive: false,
            });
        };
    }, [clickToTopHandler]);

    return (
        <ContentStyled>
            <TopButtonStyled visible={topButtonVisible} onClick={resetScroll}>
                <i className={Icons['arrowup']} />
            </TopButtonStyled>
            <Routes>
                <Route path="/" element={<Navigate to="/reservations" />} />
                <Route
                    path="/reservations/*"
                    element={
                        canReservationWrite || canReservationDelete ? (
                            <Reservation />
                        ) : null
                    }
                />
                <Route
                    path="/service-meetings/*"
                    element={
                        canServiceMeetingsWrite ? <ServiceMeetings /> : null
                    }
                />
                <Route
                    path="/maintenance/*"
                    element={canViewMaintenance ? <Maintenances /> : null}
                />
                <Route path="/my-account/help/*" element={<Help />} />

                <Route
                    path="/my-account/settings"
                    element={isAuthorized ? <Settings /> : null}
                />

                <Route
                    path="/admin/users"
                    element={isAdmin || canEditUsers ? <Users /> : null}
                />

                <Route
                    path="/admin/kh-settings"
                    element={isAdmin ? <KHSettings /> : null}
                />

                <Route path="*" element={<Navigate to="/reservations" />} />
            </Routes>
        </ContentStyled>
    );
};

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
    };
};

export default connect(mapStateToProps)(ContentRight);

const ContentStyled = styled(Box)`
    width: 100%;
    max-width: 1250px;
    min-height: 100vh;
    text-align: left;
    padding: 7px 20px;
    @media ${mediaQueries.m} {
        padding: 10px 7px;
    }

    & h1,
    & h2,
    & h3,
    & h4 {
        font-family: 'DMSans', calibri, tahoma;
        font-weight: 800;
        color: #10535f;
    }
    & h1 {
        font-size: 2rem;
    }
    & h2 {
        font-size: 1.6rem;
    }
    & h3 {
        font-size: 1.4rem;
    }
    & h4 {
        font-size: 1.4rem;
    }
`;

const TopButtonStyled = styled.button`
    background: #00b4db;
    &:hover {
        background: #0083b0;
    }
    width: 4rem;
    height: 4rem;
    color: #fff;
    border: 0;
    outline: none;
    cursor: pointer;
    border-radius: 50%;
    font-weight: 800;
    font-size: 1.8rem;
    justify-content: center;
    align-items: center;
    flex: none;
    padding: 1rem;
    position: fixed;
    right: 2rem;
    bottom: 4rem;
    display: ${(props) => (props.visible ? 'flex' : 'none')};
    @media ${mediaQueries.s} {
        bottom: 7rem;
    }
`;
