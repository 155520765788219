import { useState, useCallback } from 'react';
import axios from 'common/axios';

const useFetch = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const sendRequest = useCallback(
        ({ slug, method, token, processData, data, onCompleted }) => {
            setLoading(true);
            setError(null);
            axios({
                url: token ? `${slug}.json?auth=${token}` : `${slug}.json`,
                method,
                data,
            })
                .then((response) => {
                    if (response?.data && method === 'get' && processData) {
                        setData(processData(response.data));
                    }
                    if (onCompleted) {
                        onCompleted();
                    }
                    setLoading(false);
                })
                .catch((error) => {
                    setError(error);
                    setLoading(false);
                });
        },
        []
    );

    return {
        data,
        loading,
        error,
        sendRequest,
    };
};

export default useFetch;
