import React from 'react';
import { connect } from 'react-redux';
// import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { mediaQueries } from 'common/theme';
import { AUTHORIZATION } from 'common/constants';
import GroupCircle from 'components/GroupCircle';
import { Box, Row } from 'components/BasicComponents';
import * as actionCreators from 'store/actions/index';
import Button from './UI/Button';

const UserInfo = ({ auth, logout, openLoginScreen }) => {
    const { t } = useTranslation();
    const { authorization, group, token, logoutMessage } = auth;

    // const [lng, setLng] = useState('sk');

    // useEffect(() => {
    //   i18next.changeLanguage(lng, (error, t) => {
    //     if (error) return console.log('something went wrong loading', error);
    //   });
    // }, [lng]);

    let value = 'host';
    let privilege = t('Neschválený účet');

    if (authorization?.includes(AUTHORIZATION.APPROVED)) {
        privilege = t('Schválený účet');
        value = AUTHORIZATION.APPROVED;
    }
    if (authorization?.includes(AUTHORIZATION.ADMIN)) {
        privilege = t('Admin');
        value = AUTHORIZATION.ADMIN;
    }

    const isLoggedIn = token;

    return (
        <UserInfoWrapperStyled>
            {!!isLoggedIn && (
                <UserInfoStyled>
                    <LoginInfoTextStyled value={value}>
                        {privilege}
                        <GroupCircle number={group} />
                    </LoginInfoTextStyled>

                    <LoginInfoTextStyled name>
                        {`${localStorage.getItem(
                            'name'
                        )} ${localStorage.getItem('surname')}`}
                    </LoginInfoTextStyled>
                </UserInfoStyled>
            )}

            {!!logoutMessage && (
                <UserInfoStyled>
                    <LoginInfoTextStyled>
                        <LogoutMessageStyled>
                            {logoutMessage}
                        </LogoutMessageStyled>
                    </LoginInfoTextStyled>
                </UserInfoStyled>
            )}

            {/* {false && (
        <div className={classes.Language}>
          <select
            className={classes.LangSelect}
            value={lng}
            onChange={e => {
              setLng(e.target.value);
            }}
          >
            <option>sk</option>
            <option>cz</option>
            <option>en</option>
          </select>
        </div>
      )} */}

            <Button
                text={isLoggedIn ? t('Odhlásit') : t('Přihlásit')}
                type={isLoggedIn ? 'red' : 'green'}
                onClick={
                    isLoggedIn
                        ? () => logout(t('Odhlásení bylo úspěšné'))
                        : openLoginScreen
                }
                icon={isLoggedIn ? 'logout' : 'login'}
                hideText
            />
        </UserInfoWrapperStyled>
    );
};

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        logout: (message) => dispatch(actionCreators.logout(message)),
        openLoginScreen: () => dispatch(actionCreators.openLoginScreen()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserInfo);

const UserInfoWrapperStyled = styled(Row)`
    height: 100%;
    justify-content: space-between;
    align-items: center;
`;

const UserInfoStyled = styled(Box)`
    justify-content: center;
    align-items: center;
    @media ${mediaQueries.s} {
        display: none;
    }
`;
const LoginInfoTextStyled = styled(Row)`
    width: 100%;
    line-height: 1.3rem;
    margin: 1px 0;
    padding: 1px;
    text-align: center;
    font-size: 1rem;
    color: ${(props) =>
        props.name
            ? props.theme.colors.black4
            : props.theme.colors.privileges[props.value]};
    justify-content: center;
    align-items: center;
    font-weight: 800;
`;

const LogoutMessageStyled = styled.span`
    color: ${(props) => props.theme.colors.errorDark};
    font-weight: 800;
    padding-right: 4px;
`;
