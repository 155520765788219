import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import axios from 'common/axios';
import { API_KEY, AUTH_URL, SLUG_UPDATE } from 'common/axiosSettings.js';
import { isValidEmail } from 'common/utils';
import GroupCircle from 'components/GroupCircle';
import { Row, Box, MessageBox } from 'components/BasicComponents';
import Input from 'components/UI/Input';
import Loading from 'components/UI/Loading';
import Button from 'components/UI/Button';

const Settings = (props) => {
    const { t } = useTranslation();
    const [emailChange, setEmailChange] = useState(false);
    const [passwordChange, setPasswordChange] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [loading, setLoading] = useState('');
    const [editable, setEditable] = useState('');
    const [message, setMessage] = useState('');
    const [user, setUser] = useState({
        name: '',
        surname: '',
        email: '',
        password: '',
        dataUserID: '',
    });

    const token = props.auth.token;
    const userID = props.auth.userID;

    const loadUserSettings = useCallback(() => {
        setLoading(true);
        const queryParams = `?auth=${token}&orderBy="userID"&equalTo="${userID}"`;
        axios
            .get(`users.json${queryParams}`)
            .then((response) => {
                const id = Object.keys(response.data)[0];
                setUser((user) => ({
                    ...user,
                    name: response.data[id].name,
                    surname: response.data[id].surname,
                    dataUserID: [id],
                }));
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
            });
    }, [token, userID]);

    useEffect(() => {
        loadUserSettings();
    }, [loadUserSettings]);

    const changeInputHandler = (e) => {
        setUser((user) => ({ ...user, [e.target.name]: e.target.value }));
    };

    const postEditedUser = () => {
        if (user.name.trim() === '' || user.surname.trim() === '') {
            setMessage(t('Musíš vyplniť meno aj priezvisko'));
            return;
        }
        setLoading(true);
        const data = {
            name: user.name,
            surname: user.surname,
        };
        axios
            .patch(`users/${user.dataUserID}.json?auth=${token}`, data)
            .then((response) => {
                loadUserSettings();
                setEditable(false);
            })
            .catch((error) => console.log(error));
    };

    const errorMsgs = [
        'INVALID_ID_TOKEN',
        'TOKEN_EXPIRED',
        'CREDENTIAL_TOO_OLD_LOGIN_AGAIN',
    ];

    const userChange = (type) => {
        if (type === 'password' && user.password.trim().length < 6) {
            setErrorMessage(t('Nové heslo musí mať aspoň 6 znakov'));
            return false;
        } else if (type === 'email') {
            if (user.email.trim() === '') {
                setErrorMessage(t('Musíš vyplniť email'));
                return false;
            }
            if (!isValidEmail(user.email)) {
                setErrorMessage(t('Zadaný e-mail nie je správny'));
                return false;
            }
        }
        setErrorMessage('');
        const data = {
            idToken: token,
            returnSecureToken: false,
        };
        if (type === 'password') {
            data.password = user.password;
        } else {
            data.email = user.email;
        }

        const url = `${AUTH_URL}${SLUG_UPDATE}${API_KEY}`;

        axios
            .post(url, data)
            .then((response) => {
                setUser((user) => ({ ...user, password: '' }));
                setEmailChange(false);
                alert(
                    type === 'password'
                        ? t('Tvoje heslo bolo zmenené')
                        : t('Tvoj e-mail bol zmenený')
                );
            })
            .catch((error) => {
                let errorMessage = error?.response?.data?.error?.message;
                if (errorMsgs.includes(error?.response?.data?.error?.message)) {
                    errorMessage = t('Musíš sa znovu prihlásiť');
                } else if (
                    error?.response?.data?.error?.message === 'WEAK_PASSWORD'
                ) {
                    errorMessage = t('Slabé heslo');
                } else if (
                    error?.response?.data?.error?.message === 'EMAIL_EXISTS'
                ) {
                    errorMessage = t('Účet s týmto emailom už existuje');
                }
                setErrorMessage(errorMessage);
            });
    };

    return (
        <Box>
            <h1>{t('Nastavenia účtu')}</h1>

            {loading ? (
                <Loading />
            ) : (
                <Box>
                    <UserRowStyled>
                        {t('Zvestovateľská skupina')}:
                        <GroupCircle size="2.5rem" number={props.auth.group} />
                    </UserRowStyled>

                    <UserRowStyled>
                        {t('Meno')}:
                        {!editable && (
                            <ValueStyled>{`${user.name} ${user.surname}`}</ValueStyled>
                        )}
                    </UserRowStyled>

                    {editable && (
                        <UserRowStyled>
                            <Input
                                name="name"
                                placeholder={t('Meno')}
                                onChange={(e) => changeInputHandler(e)}
                                maxLength="15"
                                type="text"
                                value={user.name}
                            />
                            <Input
                                name="surname"
                                placeholder={t('Priezvisko')}
                                onChange={(e) => changeInputHandler(e)}
                                maxLength="20"
                                type="text"
                                value={user.surname}
                            />
                        </UserRowStyled>
                    )}

                    {!!message && <MessageBox>{message}</MessageBox>}

                    <UserRowStyled>
                        <Button
                            text={editable ? t('Uložiť') : t('Upraviť')}
                            type="blueFull"
                            onClick={
                                editable
                                    ? () => postEditedUser()
                                    : () => setEditable(true)
                            }
                            icon={editable ? 'save' : 'edit'}
                        />
                        {!!editable && (
                            <Button
                                text={t('Zrušiť')}
                                type="redFull"
                                onClick={() => setEditable(false)}
                                icon="cancel"
                            />
                        )}
                    </UserRowStyled>

                    <UserRowStyled>{t('E-mail')}:</UserRowStyled>

                    {errorMessage && (
                        <UserRowStyled>
                            <MessageBox>{errorMessage}</MessageBox>
                        </UserRowStyled>
                    )}

                    <UserRowStyled>
                        {emailChange ? (
                            <Input
                                type="text"
                                name="email"
                                value={user.email}
                                onChange={(e) => changeInputHandler(e)}
                                maxLength="38"
                            />
                        ) : (
                            <i>{props.auth.userEmail}</i>
                        )}
                    </UserRowStyled>
                    <UserRowStyled>
                        <Button
                            text={
                                emailChange ? t('Uložiť') : t('Zmeniť e-mail')
                            }
                            type={emailChange ? 'greenFull' : 'blueFull'}
                            onClick={
                                emailChange
                                    ? () => userChange('email')
                                    : () => setEmailChange(true)
                            }
                            icon={emailChange ? 'save' : 'edit'}
                        />
                        {emailChange && (
                            <Button
                                text={t('Zrušiť')}
                                type="redFull"
                                onClick={() => setEmailChange(false)}
                                icon="cancel"
                            />
                        )}
                    </UserRowStyled>

                    {passwordChange && (
                        <UserRowStyled>
                            <Input
                                type="password"
                                name="password"
                                value={user.password}
                                onChange={(e) => changeInputHandler(e)}
                                maxLength="12"
                            />
                        </UserRowStyled>
                    )}

                    <UserRowStyled>
                        <Button
                            text={
                                passwordChange ? t('Uložiť') : t('Zmeniť heslo')
                            }
                            type={passwordChange ? 'greenFull' : 'blueFull'}
                            onClick={
                                passwordChange
                                    ? () => userChange('password')
                                    : () => setPasswordChange(true)
                            }
                            icon={passwordChange ? 'save' : 'edit'}
                        />
                        {passwordChange && (
                            <Button
                                text={t('Zrušiť')}
                                type="redFull"
                                onClick={() => setPasswordChange(false)}
                                icon="cancel"
                            />
                        )}
                    </UserRowStyled>
                </Box>
            )}
        </Box>
    );
};

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
    };
};

export default connect(mapStateToProps)(Settings);

const ValueStyled = styled.span`
    color: rgb(9, 138, 99);
    font-weight: 700;
    margin-left: 7px;
`;

const UserRowStyled = styled(Row)`
    padding: 5px 10px;
    min-height: 40px;
    align-items: center;
    justify-content: flex-start;
`;
