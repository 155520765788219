const ALL_CONGREGATIONS = {
    HAVIROV_ZAPAD: 'HAVIROV_ZAPAD',
};

export const CONGREGATION = ALL_CONGREGATIONS.HAVIROV_ZAPAD;

export const CONGREGATIONS = {
    HAVIROV_ZAPAD: {
        apiKey: 'AIzaSyByIxregTn6l84-BONC_P98SiZ1Zr8Zc7k',
        baseUrl:
            'https://udrzba-f301d-default-rtdb.europe-west1.firebasedatabase.app/',
        domain: 'udrzba.enase.eu',
    },
};
