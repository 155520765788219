import React from 'react';
import { useTranslation } from 'react-i18next';
import styled, { keyframes } from 'styled-components';

import { Box } from 'components/BasicComponents';

export const VERSION = '0.9.0';

const Help = () => {
    const { t } = useTranslation();

    return (
        <HelpStyled>
            <h2>{t('Zmeny plánované v najbližších verziách')}</h2>
            <ChangesStyled className="next"></ChangesStyled>

            <HeadingStyled>{t('Zoznam zmien')}</HeadingStyled>
            <ChangesStyled className="past">
                <Version>{`${VERSION} (1.1. 2024)`}</Version>
                <New>pridaný modul údržba</New>
                <Version>0.2.0 (20.12. 2023)`}</Version>
                <Imp>preklad niektorých slov do češtiny</Imp>
                <Imp>pridaná legenda s farbami miestností</Imp>
                <New>pridaný modul schôdzok pred službou</New>
                <Version>0.1.0 (15.12. 2023)</Version>
                <New>
                    vytvorená databáza, subdoména, základná kostra aplikácie
                </New>
            </ChangesStyled>
        </HelpStyled>
    );
};

export default Help;

const rotate = keyframes`
0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const HeadingStyled = styled.h1`
    margin-bottom: 0;
`;

const HelpStyled = styled(Box)`
    font-size: 1.4rem;
`;

const ChangesStyled = styled.ul`
    padding-left: 0px;
    margin: 0;
    text-align: left;
    list-style-type: none;
    & li {
        padding: 5px;
    }
    & li::before {
        margin-right: 7px;
        font-family: 'Font Awesome 5 Free';
        font-weight: 600;
    }
    &.next li::before {
        content: '\f252';
        display: inline-block;
        animation: ${rotate} 4s infinite ease-in-out;
    }
`;

const Fix = styled.li`
    background: rgba(180, 40, 0, 0.05);
    color: rgba(180, 40, 0);
    &::before {
        content: '\f7d9';
        color: rgba(180, 40, 0);
    }
`;
const Imp = styled.li`
    background: rgba(0, 91, 211, 0.05);
    color: rgb(0, 57, 132);
    &::before {
        content: '\f102';
        color: rgb(0, 91, 211);
    }
`;

const New = styled.li`
    background: rgba(20, 150, 20, 0.05);
    color: rgb(50, 100, 50);
    &::before {
        content: '\f0fe';
        color: rgb(20, 150, 20);
    }
`;

const Version = styled.li`
    color: rgb(50, 61, 115);
    margin-top: 10px;
    font-weight: 800;
    font-size: 1.6rem;
`;
