import React from 'react';
import styled from 'styled-components';

import { Row } from 'components/BasicComponents';

const GroupCircle = ({ number = null, size = '1.5rem' }) => {
    if (!number || isNaN(+number)) {
        return null;
    }

    const value = `group${+number}`;

    return (
        <GroupCircleStyled value={value} size={size}>
            {+number}
        </GroupCircleStyled>
    );
};

export default GroupCircle;

const GroupCircleStyled = styled(Row)`
    background: ${(props) =>
        props.theme?.colors?.groups?.[props.value]?.primary};
    color: ${(props) =>
        props.theme?.colors?.groups?.[props.value]?.color || '#fff'};
    width: ${(props) => props.size};
    height: ${(props) => props.size};
    min-width: ${(props) => props.size};
    min-height: ${(props) => props.size};
    font-size: calc(${(props) => props.size} - 25%);
    border-radius: 50%;
    margin: 0 0.3rem;
    font-weight: 800;
    justify-content: center;
    align-items: center;
    text-align: center;
`;
