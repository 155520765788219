import React from 'react';
import styled from 'styled-components';

import { mediaQueries } from 'common/theme';
import { Row } from 'components/BasicComponents';
import Menu from './Menu';
import ContentRight from './ContentRight';

const Content = () => (
    <ContentStyled>
        <MenuWrapperStyled>
            <Menu />
        </MenuWrapperStyled>
        <ContentRight />
    </ContentStyled>
);

export default Content;

const ContentStyled = styled(Row)`
    width: 100%;
    height: 100%;
    overflow-x: scroll;
    padding-top: ${(props) => props.theme.headerHeight.desktop};
    padding-bottom: 40px;
    @media ${mediaQueries.s} {
        padding-top: ${(props) => props.theme.headerHeight.compact};
    }
    @media ${mediaQueries.s} {
        padding-top: 0px;
        padding-bottom: ${(props) => props.theme.headerHeight.mobile};
    }
`;

const MenuWrapperStyled = styled.div`
    @media ${mediaQueries.s} {
        display: none;
    }
`;
