import React from 'react';
import styled from 'styled-components';

import { mediaQueries } from 'common/theme';
import { Row } from 'components/BasicComponents';
import { Portal } from '../Portal';

import back from 'assets/images/back.jpg';

const bgGradients = {
    spring: {
        start: '#00b09b',
        end: '#96c93d',
    },
    summer: {
        start: '#CAC531',
        end: '#F3F9A7',
    },
    autumn: {
        start: '#c21500',
        end: ' #ffc500',
    },
    winter: {
        start: '#2980b9',
        end: '#6dd5fa, #ffffff',
    },
};

const Backdrop = ({
    isLoginBackdrop,
    isTransparent,
    isOpened,
    onClick,
    children,
}) => {
    const actualSeason = () => {
        const date = new Date().getMonth() + 1;
        if (date >= 3 && date <= 5) {
            return 'spring';
        }
        if (date >= 6 && date <= 8) {
            return 'summer';
        }
        if (date >= 9 && date <= 11) {
            return 'autumn';
        }
        return 'winter';
    };

    const gradientStart = bgGradients[actualSeason()].start;
    const gradientEnd = bgGradients[actualSeason()].end;

    const background = isTransparent ? 'transparent' : 'rgba(0, 0, 0, 0.5)';

    return (
        <Portal type="backdrop-root">
            <BackdropStyled
                onClick={onClick}
                isTransparent={isTransparent}
                isLoginBackdrop={isLoginBackdrop}
                isOpened={isOpened}
                background={background}
                gradientStart={gradientStart}
                gradientEnd={gradientEnd}
            >
                {children}
            </BackdropStyled>
        </Portal>
    );
};

export default Backdrop;

const BackdropStyled = styled(Row)`
    width: 100%;
    height: 100%;
    position: fixed;
    margin: 0;
    padding: 0;
    justify-content: center;
    align-items: center;
    background: ${(props) =>
        props.isLoginBackdrop
            ? `linear-gradient(
      to right,
      ${props.gradientStart},
      ${props.gradientEnd}bb
    ),
    url(${back}) no-repeat center center;`
            : props.background};
    backdrop-filter: ${(props) =>
        props.isTransparent ? '' : 'blur(3px) grayscale(70%)'};
    transform: ${(props) =>
        props.isLoginBackdrop
            ? `translateY(${props.isOpened ? '0' : '-100'}%)`
            : ''};
    transition: all ${(props) => (props.isLoginBackdrop ? '0.2' : '0.1')}s;
    background-size: ${(props) => (props.isLoginBackdrop ? 'cover' : '')};
    opacity: ${(props) =>
        props.isLoginBackdrop ? '' : props.isOpened ? '1' : '0'};
    z-index: ${(props) =>
        props.isLoginBackdrop
            ? props.isOpened
                ? props.theme.zIndex.backdropLoginOpened
                : props.theme.zIndex.backdrop
            : props.isOpened
            ? props.theme.zIndex.backdropOpened
            : props.theme.zIndex.backdrop};

    @media ${mediaQueries.mMin} {
        display: ${(props) =>
            props.isLoginBackdrop || props.isTransparent ? '' : 'none'};
    }
`;
