import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { mediaQueries } from 'common/theme';
import { Row, Box, MessageBox } from 'components/BasicComponents';
import Button from 'components/UI/Button';
import Input from 'components/UI/Input';

export const rooms = [
    {
        id: '01',
        name: 'Hlavní sál',
    },
    {
        id: '02',
        name: 'Malý sál',
    },
    {
        id: '03',
        name: 'Zasedačka',
    },
    {
        id: '04',
        name: 'Celá budova',
    },
];

const ReservationEdit = ({ data, change, message, postHandler }) => {
    const { t } = useTranslation();
    const { date, time, event, room } = data;

    return (
        <BoxStyled>
            <h1>{t('Přidat novou událost')}</h1>
            <InputsWrapperStyled>
                <Box>
                    <Row>
                        <Input
                            type="date"
                            value={date}
                            name="date"
                            onChange={change}
                        />
                        <Input
                            type="time"
                            value={time}
                            name="time"
                            onChange={change}
                        />{' '}
                    </Row>
                    <Row>
                        <Input
                            type="text"
                            value={event}
                            name="event"
                            onChange={change}
                            placeholder="Událost"
                            width="100%"
                            textAlign="left"
                            size="40"
                            maxLength="35"
                        />
                    </Row>
                    <Row>
                        <Input
                            type="select"
                            value={room}
                            name="room"
                            onChange={change}
                        >
                            {rooms.map((room, i) => (
                                <option key={`${room.id}_${i}`} value={room.id}>
                                    {room.name}
                                </option>
                            ))}
                        </Input>
                    </Row>
                </Box>
            </InputsWrapperStyled>
            {!!message && <MessageBox>{message}</MessageBox>}
            <Button
                text={t('Přidat událost')}
                type="greenFull"
                onClick={postHandler}
                icon="plus"
                my="1rem"
            />
        </BoxStyled>
    );
};

export default ReservationEdit;

const BoxStyled = styled(Box)`
    margin-bottom: 30px;
`;
const InputsWrapperStyled = styled(Row)`
    width: 90%;
    justify-content: space-between;
    align-items: center;

    @media ${mediaQueries.m} {
        flex-direction: column;
    }
`;
