import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Box, MessageBox } from 'components/BasicComponents';
import Input from 'components/UI/Input';
import Button from 'components/UI/Button';

const LoginContent = ({
    signIn,
    user,
    changeHandler,
    postAuth,
    passwordReset,
    passwordResetF,
    beforeSignInToggler,
    message,
    errorMessage,
    regSuccessMessage,
}) => {
    const { t } = useTranslation();

    return (
        <WrapperStyled
            onClick={(e) => {
                e.stopPropagation();
            }}
        >
            {signIn ? t('Přihlásit se') : t('Vytvořit nový účet')}
            <form>
                <WrapperStyled>
                    <InputWrapperStyled>
                        <Input
                            name="email"
                            type="email"
                            autoComplete={
                                signIn ? 'current-password' : 'new-password'
                            }
                            placeholder={
                                signIn
                                    ? t('Přihlasovací email')
                                    : t('Emailová adresa')
                            }
                            maxLength="35"
                            value={user.email}
                            onChange={(e) => changeHandler(e)}
                            width="180px"
                            height="35px"
                        />
                        <Input
                            name="password"
                            type="password"
                            minLength="6"
                            maxLength="15"
                            autoComplete={
                                signIn ? 'current-password' : 'new-password'
                            }
                            placeholder={
                                signIn ? t('Hesielko') : t('Nové hesielko')
                            }
                            value={user.password}
                            onChange={(e) => changeHandler(e)}
                            width="180px"
                            height="35px"
                        />
                        {!signIn && (
                            <>
                                <Input
                                    type="text"
                                    name="name"
                                    placeholder={t('Jméno')}
                                    maxLength="15"
                                    value={user.name}
                                    onChange={(e) => changeHandler(e)}
                                    width="180px"
                                    height="35px"
                                />

                                <Input
                                    type="text"
                                    name="surname"
                                    placeholder={t('Příjmení')}
                                    maxLength="20"
                                    value={user.surname}
                                    onChange={(e) => changeHandler(e)}
                                    width="180px"
                                    height="35px"
                                />

                                <Input
                                    type="text"
                                    name="congregation"
                                    placeholder={t('Sbor')}
                                    maxLength="20"
                                    value={user.congregation}
                                    onChange={(e) => changeHandler(e)}
                                    width="180px"
                                    height="35px"
                                />
                            </>
                        )}
                    </InputWrapperStyled>
                    {!!message && <MessageBox>{message}</MessageBox>}
                    {!!errorMessage && <MessageBox>{errorMessage}</MessageBox>}
                    {!!regSuccessMessage && (
                        <MessageBox success>{regSuccessMessage}</MessageBox>
                    )}
                    <Button
                        text={signIn ? t('Přihlásit se') : t('Vytvořit účet')}
                        type="green"
                        onClick={
                            signIn
                                ? (e) => postAuth(e, 'signIn')
                                : (e) => postAuth(e, 'signUp')
                        }
                        icon={signIn ? 'login' : 'plus'}
                        my=".5rem"
                    />
                </WrapperStyled>
            </form>

            {signIn && !passwordReset && (
                <Button
                    text={t('Zapoměl jsem heslo')}
                    type="orange"
                    onClick={passwordResetF}
                    icon="unlock"
                    my=".5rem"
                />
            )}
            <Button
                text={
                    signIn
                        ? t('Vytvořit nový účet')
                        : t('Již mám vytvořený účet')
                }
                type="blue"
                onClick={beforeSignInToggler}
                icon={signIn ? 'plus' : 'user'}
            />
        </WrapperStyled>
    );
};

export default LoginContent;

const WrapperStyled = styled(Box)`
    align-items: center;
`;
const InputWrapperStyled = styled(Box)`
    margin-bottom: 0.8rem;
`;
