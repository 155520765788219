import React from 'react';
import styled from 'styled-components';

import { Box, Row } from 'components/BasicComponents';

export const SettingsSectionsWrapper = ({ children }) => (
    <SettingsSectionsWrapperStyled>{children}</SettingsSectionsWrapperStyled>
);

export const SettingsSection = ({ type, heading, data }) => (
    <SettingsSectionStyled type={type}>
        <SectionHeadingStyled>{heading}</SectionHeadingStyled>
        <SettingsWrapperStyled>{data}</SettingsWrapperStyled>
    </SettingsSectionStyled>
);

const SettingsSectionsWrapperStyled = styled(Box)`
    width: 100%;
    margin: 1rem 0;
`;

const SettingsSectionStyled = styled(Box)`
    color: ${(props) => props.theme.colors.tasks[props.type]};
    border-color: ${(props) => props.theme.colors.tasks[props.type]};
    width: 100%;
    padding-top: 0.7rem;
    border-top: 1px dashed;
`;

const SectionHeadingStyled = styled(Row)`
    font-weight: 800;
    padding-left: 1rem;
`;

const SettingsWrapperStyled = styled(Row)`
    width: 100%;
    margin: 0.5rem 0;
    flex-wrap: wrap;
`;
