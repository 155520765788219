import React from 'react';
import styled, { keyframes } from 'styled-components';

import spinner from 'assets/images/loading.svg';

const Loading = () => <LoadingStyled />;

export default Loading;

const rotate = keyframes`
0% {
      transform: rotate(0deg);
    }
    50% {
      transform: rotate(180deg);
    }
    100% {
      transform: rotate(360deg);
    }`;

const LoadingStyled = styled.div`
    background: url(${spinner}) no-repeat center center;
    background-size: contain;
    width: 5rem;
    height: 5rem;
    animation: ${rotate} 0.7s infinite linear;
`;
