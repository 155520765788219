import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useReactToPrint } from 'react-to-print';
import styled from 'styled-components';
import axios from 'common/axios';

import useFetch from 'hooks/useFetch';
import * as actionCreators from 'store/actions';
import { genericSort, isOldDay } from 'common/utils';
import Button from 'components/UI/Button';
import { Row, Box } from 'components/BasicComponents';
import { confirmModalAsync } from 'components/UI/ConfirmModal';
import Loading from 'components/UI/Loading';
import { VERSION } from '../../myAccount/Help';

import ReservationEdit from './ReservationEdit';
import Reservation, { backgrounds } from './Reservation';
import { AUTHORIZATION } from 'common/constants';

const processReservation = (data) => {
    const newData = [];
    Object.keys(data).forEach((id) => {
        newData.push({
            date: data[id].date,
            time: data[id].time,
            event: data[id].event,
            room: data[id].room,
            congregation: data[id].congregation,
            author: data[id].author,
            old: data[id]?.date
                ? isOldDay(data[id].date)
                    ? true
                    : false
                : false,
            id: id,
        });
    });
    return newData;
};

const Reservations = (props) => {
    const { t } = useTranslation();

    const [isPrinting, setIsPrinting] = useState(false);

    const [reservation, setReservation] = useState({
        date: '',
        time: '',
        event: '',
        room: '',
    });

    const [message, setMessage] = useState('');
    const [reservations, setReservations] = useState([]);
    const [editable, setEditable] = useState('');
    const [hideOld, setHideOld] = useState(true);

    const { data, loading, sendRequest } = useFetch();

    const token = props.auth.token;
    const congregation = props.auth.congregation;
    const name = props.auth.name;
    const surname = props.auth.surname;

    const canWrite =
        token &&
        props.auth.authorization?.includes(AUTHORIZATION.RESERVATION_WRITE);
    const canDelete =
        token &&
        props.auth.authorization?.includes(AUTHORIZATION.RESERVATION_DELETE);

    const changeHandler = (e) => {
        const newMeeting = { ...reservation };
        newMeeting[e.target.name] = e.target.value;

        setReservation(newMeeting);
    };

    const fetchVersion = useCallback(() => {
        axios
            .get('version.json')
            .then((response) => {
                return !response.data || response.data === VERSION;
            })
            .then((result) => {
                if (!result) {
                    window.location.reload(true);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    const loadReservation = useCallback(
        () =>
            sendRequest({
                slug: 'reservations',
                method: 'get',
                token,
                processData: processReservation,
            }),
        [sendRequest, token]
    );

    const postServiceMeetings = (data) =>
        sendRequest({
            slug: 'reservations',
            method: 'post',
            token,
            data: { ...data, congregation, author: `${surname} ${name}` },
            onCompleted: loadReservation,
        });

    const patchServiceMeetings = (id, data) =>
        sendRequest({
            slug: `reservations/${id}`,
            method: 'patch',
            token,
            data,
            onCompleted: loadReservation,
        });

    const deleteReservation = (id, onCompleted) =>
        sendRequest({
            slug: `reservations/${id}`,
            method: 'delete',
            token,
            onCompleted,
        });

    useEffect(() => {
        loadReservation();
        fetchVersion();
    }, [loadReservation, fetchVersion]);

    useEffect(() => {
        setReservations(data);
    }, [data]);

    const postHandler = () => {
        if (
            reservation.date === '' ||
            reservation.time === '' ||
            reservation.event === ''
        ) {
            setMessage(t('Nevyplnil si čas, datum nebo událost!'));
            return;
        }
        setMessage('');
        postServiceMeetings(reservation);
        setReservation({
            date: '',
            time: '',
            event: '',
            room: '01',
        });
    };

    const beforeRemoveHandler = async (reservation) => {
        const confirmResult = await confirmModalAsync({
            props: props,
            title: t('Odstrániť udalosť'),
            question: t('Naozaj chceš odstrániť túto udalosť?'),
        });

        if (confirmResult) {
            removeHandler(reservation);
        }
    };

    const removeHandler = (reservation) => {
        const newServiceMeetings = reservations.filter(
            (item) => item.id !== reservation.id
        );
        deleteReservation(reservation.id, setReservations(newServiceMeetings));
    };

    const reservationEdit = (e, id) => {
        const wantedIndex = reservations.findIndex((row) => row.id === id);
        const newServiceMeetings = [...reservations];

        newServiceMeetings[wantedIndex][e.target.name] = e.target.value;

        setReservations(newServiceMeetings);
    };

    const postEditedReservation = (id) => {
        const data = reservations.find((reservation) => reservation.id === id);
        patchServiceMeetings(id, data);
        setEditable('');
    };

    const componentRef = useRef();
    const doPrint = useReactToPrint({
        content: () => componentRef.current,
    });
    const handlePrint = () => {
        setIsPrinting(true);
    };

    useEffect(() => {
        if (isPrinting) {
            doPrint();
            setTimeout(() => setIsPrinting(false), 100);
        }
    }, [isPrinting, doPrint]);

    const hasOldMeetings = !!reservations?.some((meeting) => meeting.old);

    const sortedByTime = genericSort(reservations, false, 'time', 'time');

    const sortedByDate = genericSort(sortedByTime, false, 'date', 'date');

    const activeMeetings = hideOld
        ? sortedByDate?.filter((meeting) => !meeting.old)
        : sortedByDate;

    const serviceMeetingsList = [];
    activeMeetings.forEach((reservation) => {
        serviceMeetingsList.push(
            <Reservation
                data={reservation}
                key={reservation.id}
                isEditable={editable === reservation.id}
                edit={(e) => reservationEdit(e, reservation.id)}
                blueButton={
                    editable === reservation.id
                        ? () => postEditedReservation(reservation.id)
                        : () => setEditable(reservation.id)
                }
                redButton={
                    editable === reservation.id
                        ? () => setEditable(false)
                        : () => beforeRemoveHandler(reservation)
                }
                isPrinting={isPrinting}
                canWrite={canWrite}
                canDelete={canDelete}
            />
        );
    });

    return (
        <div>
            <Routes>
                <Route
                    path="edit"
                    element={
                        canWrite ? (
                            <ReservationEdit
                                key="reservation"
                                change={changeHandler}
                                postHandler={postHandler}
                                data={reservation}
                                message={message}
                            />
                        ) : (
                            <Navigate to="/reservations" />
                        )
                    }
                />
            </Routes>
            <ButtonsRowStyled>
                {hasOldMeetings && !isPrinting && (
                    <Button
                        text={
                            hideOld ? t('Zobraziť staršie') : t('Skryť staršie')
                        }
                        type={hideOld ? 'blue' : 'orange'}
                        icon={'history'}
                        onClick={() => setHideOld((hideOld) => !hideOld)}
                    />
                )}
                <Button
                    text={t('Vytlačiť')}
                    type={'purpleFull'}
                    icon={'print'}
                    onClick={handlePrint}
                />
            </ButtonsRowStyled>
            <BoxStyled ref={componentRef}>
                <h1>{t('Rezervační systém')}</h1>
                <ColorCirclesStyled>
                    <ColorRowStyled>
                        <ColorCircleStyled bg={backgrounds['01']} />
                        Hlavní sál
                    </ColorRowStyled>
                    <ColorRowStyled>
                        <ColorCircleStyled bg={backgrounds['02']} />
                        Malý sál
                    </ColorRowStyled>
                    <ColorRowStyled>
                        <ColorCircleStyled bg={backgrounds['03']} />
                        Zasedačka
                    </ColorRowStyled>
                    <ColorRowStyled>
                        <ColorCircleStyled bg={backgrounds['04']} />
                        Celá budova
                    </ColorRowStyled>
                </ColorCirclesStyled>
                {!!reservations.length && <Reservation isLabel />}
                {!!loading && <Loading />}
                {serviceMeetingsList}
            </BoxStyled>
        </div>
    );
};
const mapStateToProps = (state) => {
    return {
        auth: state.auth,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        openModal: (component, componentProps, title) =>
            dispatch(
                actionCreators.openModal(component, componentProps, title)
            ),
        closeModal: () => dispatch(actionCreators.closeModal()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Reservations);

const BoxStyled = styled(Box)`
    align-items: flex-start;
`;
const ColorCirclesStyled = styled(Row)`
    margin-bottom: 10px;
    width: auto;
    font-weight: 700;
    flex-wrap: wrap;
`;
const ColorRowStyled = styled(Row)`
    align-items: center;
    padding: 5px;
    padding-right: 15px;
`;
export const ColorCircleStyled = styled(Box)`
    width: 12px;
    min-width: 12px;
    height: 12px;
    background: ${(props) => props.bg};
    margin: 5px;
    border: 1px solid #000;
    border-radius: 50%;
`;
const ButtonsRowStyled = styled(Row)`
    max-width: 90rem;
    justify-content: space-between;
`;
