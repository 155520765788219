export const sizes = {
    menuS: [295, 'px'],
    menuM: [330, 'px'],
    logoHide: [360, 'px'],
    xs: [400, 'px'],
    s: [600, 'px'],
    m: [800, 'px'],
    l: [1000, 'px'],
    xl: [1200, 'px'],
};

export const mediaQueries = {
    menuS: `(max-width: ${sizes.menuS.join('')})`,
    menuM: `(max-width: ${sizes.menuM.join('')})`,
    logoHide: `(max-width: ${sizes.logoHide.join('')})`,
    xs: `(max-width: ${sizes.xs.join('')})`,
    s: `(max-width: ${sizes.s.join('')})`,
    m: `(max-width: ${sizes.m.join('')})`,
    mMin: `(min-width: ${sizes.m.join('')})`,
    l: `(max-width: ${sizes.l.join('')})`,
    xl: `(min-width: ${sizes.xl.join('')})`,
};

export const theme = {
    breakpoints: Object.keys(sizes).map((key) => sizes[key].join('')),
    headerHeight: {
        desktop: '5rem',
        compact: '4rem',
        mobile: '5.5rem',
    },
    reportWarningHeight: '3.5rem',

    headerVisibleThreshold: 80,
    headerCompactThreshold: 80,
    permanentCompactHeaderWidth: 800,

    zIndex: {
        backdrop: -1,
        header: 2,
        backdropLoginOpened: 3,
        reportWarning: 5,
        loginScreen: 20,
        backdropOpened: 21,
        sideDrawer: 120,
        modal: 500,
    },

    radius: {
        xs: '0.4rem',
        s: '0.8rem',
        m: '1rem',
        l: '1.2rem',
        xl: '1.6rem',
        xxl: '2rem',
    },

    colors: {
        white8: '#ffffffcc',
        white6: '#ffffff99',
        white4: '#ffffff66',
        white2: '#ffffff33',
        white1: '#ffffff1A',
        white0: '#ffffff0D',
        black8: '#000000cc',
        black6: '#00000099',
        black4: '#00000066',
        black2: '#00000033',
        black1: '#0000001A',
        black0: '#0000000D',
        modal: '#f5f5f5b3',
        logo: '#19213f',
        header: '#f6f7f880',
        heading: '#0e2f55',
        label: '#10535f',
        labelLight: '#33317c20',
        reportWarningPrimary: '#eb3349',
        reportWarningSecondary: '#f45c43',
        errorDark: '#e72e2e',
        error: '#f67273',
        errorLight: '#f6727350',
        errorTerritory: '#f6727320',
        errorText: '#000',
        warning: '#ffbc00',
        warningLight: '#ffbc0040',
        territorySort: '#1caace1a',
        warningText: '#382900',
        success: '#AFE166',
        successLight: '#AFE16680',
        successText: '#223702',
        userIcon: '#1caace',
        circlePioneer: '#1ab927',
        editable: { background: '#d4f1d4', color: '#40b946' },
        active: { primary: '#a8e063cc', secondary: '#a8e06366' },
        myTaskPrimary: '#c72246',
        myTaskSecondary: '#83112e',
        progress: '#ff8008',
        progressCompleted: '#52b56a',

        buttons: {
            gray: { light: '#606066', dark: '#75757c' },
            black: { light: '#272729', dark: '#171718' },
            red: { light: '#ef473a', dark: '#cb2d3e' },
            green: { light: '#45C575', dark: '#11998e' },
            blue: { light: '#00b4db', dark: '#0083b0' },
            orange: { light: '#f7b733', dark: '#fc4a1a', color: '#000' },
            yellow: { light: '#dce35b', dark: '#45b649', color: '#000' },
            purple: { light: '#8f94fb', dark: '#4e54c8' },
            disabled: {
                light: '#92929216',
                dark: '#92929216',
                color: '#92929277',
                hoverColor: '#92929277',
            },
            close: { light: '#ef473a', dark: '#ef473a' },
            plus: {
                primaryLight: '#1dd1a1',
                primaryDark: '#14b88c',
                hoverLight: '#23daa9',
                hoverDark: '#18c093',
                color: '#cafff1',
            },
            minus: {
                primaryLight: ' #ee5253',
                primaryDark: '#ce3d3d',
                hoverLight: '#e04c4c',
                hoverDark: '#c73d3d',
                color: '#ffdbdb',
            },
        },

        schedule: {
            active: '#27ac440d',
            gray: { background: '#626262', dataColor: '#42494e' },
            orangeDark: {
                background: '#c48430',
                color: '#181002',
                dataColor: '#7e5309',
            },
            red: { background: '#932826', dataColor: '#55000b' },
            green: {
                background: '#34CA73',
                color: '#06190E',
                dataColor: '#0B2E1A',
            },
            blue: { background: '#2B73AB', dataColor: '#0A1C28' },
            yellow: { background: '#E1C783', dataColor: '#55000b' },
            pink: { background: '#E18492', dataColor: '#55000b' },
            purple: { background: '#7998EE', dataColor: '#55000b' },
            orange: { background: '#ffc000' },
        },

        duplications: {
            first: { background: '#e9ba53', color: '#473205' },
            second: { background: '#fa7436', color: '#441702' },
            third: { background: '#f71010', color: '#2c0303' },
        },

        groups: {
            group1: { primary: '#04befe' },
            group2: { primary: '#31ae3d' },
            group3: { primary: '#ff5e62' },
            group4: { primary: '#ffe259', color: '#4e3c00' },
            group5: { primary: '#868cff' },
            group6: { primary: '#eca1ad' },
            group7: { primary: '#ff9800' },
            group8: { primary: '#ad1457' },
            group9: { primary: '#80cbc4' },
            group10: { primary: '#90a4ae' },
        },

        privileges: {
            host: 'rgba(0, 0, 0, 0.3)',
            approved: '#7eac3b',
            admin: '#d81e56',
        },

        seasons: {
            spring: {
                primary: '#00b09b',
                secondary: '#96c93d',
            },
            summer: {
                primary: '#CAC531',
                secondary: '#F3F9A7',
            },
            autumn: {
                primary: '#c21500',
                secondary: ' #ffc500',
            },
            winter: {
                primary: '#2980b9',
                secondary: '#6dd5fa',
                tertiary: '#ffffff',
            },
        },

        tasks: {
            tasks: '#666326',
            tasksTechnical: '#792a12',
            privileges: '#3d6617',
            user: '#225170',
            substitute: '#039746',
        },

        report: {
            pioneer: '#1ab927',
            auxPioneer: '#ff5e62',
            hover: '#6dd5fa',
            registered: '#ffdbdb66',
            completed: '#DCF9E1',
        },
        application: {
            registered: '#dadada',
            approved: '#e0efd1',
            notApproved: '#f7d8cf',
            hours: '#188a71',
            active: '#0bc093',
            yes: '#00a03d',
            no: '#c21d00',
            notApprovedUser: '#ffa500',
            notApprovedColor: '#6b4600',
        },
        counts: {
            week: '#1F5D31',
            weekend: '#D63737',
            all: '#2980b9',
        },

        fonts: {
            body: 'DMSans, calibri, tahoma',
            heading: 'DMSans, calibri, tahoma',
        },
        fontWeights: {
            normal: 400,
            medium: 600,
            bold: 800,
        },
    },
};
