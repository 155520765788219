import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styled, { keyframes } from 'styled-components';

import { theme, mediaQueries } from 'common/theme';
import { Row } from 'components/BasicComponents';
import { DAYS } from 'common/constants';
import { Icons } from 'common/Icons';
import Button from 'components/UI/Button';
import Input from 'components/UI/Input';

import { rooms } from './ServiceMeetingsEdit';
import { ColorCircleStyled } from '../Reservation/Reservations';

export const backgrounds = {
    '01': '#FFFFFF',
    '02': '#fbfb74',
    '03': '#b4fb74',
};

const ServiceMeeting = ({
    data,
    edit,
    isEditable,
    blueButton,
    redButton,
    isLabel,
    isPrinting,
}) => {
    const { t } = useTranslation();
    const { day, time, name, room } = data || {};

    console.log(room);

    const renderedBackground = backgrounds[room];

    if (isLabel) {
        return (
            <ServiceMeetingStyled isLabel>
                <WrapperStyled>
                    <ColumnStyled className="day">{t('Den')}</ColumnStyled>
                    <ColumnStyled className="time">{t('Čas')}</ColumnStyled>
                    <ColumnStyled className="name">{t('Kdo')}</ColumnStyled>
                    <ColumnStyled className="room">{t('Místo')}</ColumnStyled>
                    <ColumnStyled className="buttons"></ColumnStyled>
                </WrapperStyled>
            </ServiceMeetingStyled>
        );
    }

    const today = new Date().getDay() === 0 ? 7 : new Date().getDay();

    const isToday = today === +day;
    const isActive = !isPrinting && isToday;

    const serviceMeeting = (showButtons) => {
        return (
            <ServiceMeetingStyled
                bg={renderedBackground}
                isEditable={isEditable}
                isActive={isActive}
            >
                <WrapperStyled>
                    <DateRowStyled>
                        <ColumnStyled className="day">
                            <i className={Icons.calendar} />
                            {isEditable ? (
                                <Input
                                    type="select"
                                    value={day}
                                    name="day"
                                    onChange={(e) => edit(e)}
                                >
                                    {Object.keys(DAYS).map((day, i) => (
                                        <option key={`${day}_${i}`} value={day}>
                                            {DAYS[day]}
                                        </option>
                                    ))}
                                </Input>
                            ) : (
                                DAYS[day]
                            )}
                        </ColumnStyled>

                        <ColumnStyled className="time">
                            {isEditable ? (
                                <Input
                                    type="time"
                                    value={time}
                                    name="time"
                                    onChange={(e) => edit(e)}
                                />
                            ) : (
                                time
                            )}
                        </ColumnStyled>
                        <ColumnStyled className="name">
                            {isEditable ? (
                                <Input
                                    type="text"
                                    value={name}
                                    name="name"
                                    onChange={(e) => edit(e)}
                                />
                            ) : (
                                name
                            )}
                        </ColumnStyled>
                        <ColumnStyled className="room">
                            {isEditable ? (
                                <Input
                                    type="select"
                                    value={room}
                                    name="room"
                                    onChange={(e) => edit(e)}
                                >
                                    {rooms.map((room, i) => (
                                        <option
                                            key={`${room.id}_${i}`}
                                            value={room.id}
                                        >
                                            {room?.name}
                                        </option>
                                    ))}
                                </Input>
                            ) : (
                                <Row>
                                    <ColorCircleStyled bg={backgrounds[room]} />
                                    {
                                        rooms.find((item) => item.id === room)
                                            ?.name
                                    }
                                </Row>
                            )}
                        </ColumnStyled>
                    </DateRowStyled>

                    {showButtons && (
                        <ColumnStyled className="buttons">
                            <Button
                                type={isEditable ? 'greenFull' : 'blueFull'}
                                onClick={blueButton}
                                icon={isEditable ? 'save' : 'edit'}
                                mx="5px"
                                my="5px"
                            />
                            <Button
                                type="redFull"
                                onClick={redButton}
                                icon={isEditable ? 'cancel' : 'delete'}
                                mx="5px"
                                my="5px"
                            />
                        </ColumnStyled>
                    )}
                </WrapperStyled>
            </ServiceMeetingStyled>
        );
    };

    return (
        <Routes>
            <Route path="edit" element={serviceMeeting(!isPrinting)} />
            <Route path="*" element={serviceMeeting()} />
        </Routes>
    );
};

export default ServiceMeeting;

const highlight = keyframes`
  0% {
    background: ${theme.colors.active.secondary};
  }
  50% {
    background: ${theme.colors.active.primary};
  }
  100% {
    background: ${theme.colors.active.secondary};
  }
`;

const ServiceMeetingStyled = styled(Row)`
    background: ${(props) =>
        props.isLabel
            ? `${props.theme.colors.label}22`
            : props.isEditable
            ? props.theme.colors.editable.background
            : ''};
    color: ${(props) => (props.isLabel ? props.theme.colors.label : '')};
    font-weight: ${(props) => (props.isLabel ? '800' : '')};
    width: 100%;
    max-width: 90rem;
    min-height: 2.5rem;
    line-height: 2.5rem;
    border-bottom: ${(props) =>
        props.isLabel ? 0 : `1px solid rgba(0, 0, 0, 0.1)`};
    padding: 0.5rem;
    animation-name: ${(props) => (props.isActive ? highlight : '')};
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    @media ${mediaQueries.s} {
        display: ${(props) => (props.isLabel ? 'none' : '')};
    }
`;

const DateRowStyled = styled(Row)`
    @media ${mediaQueries.s} {
        width: 100%;
        justify-content: space-between;
        flex-wrap: wrap;
    }
`;

const WrapperStyled = styled(Row)`
    width: 100%;
    @media ${mediaQueries.s} {
        flex-direction: column;
        align-items: center;
        padding: 0.5rem;
    }
`;

const ColumnStyled = styled(Row)`
    text-align: center;
    padding: 0 0.5rem;
    align-items: center;
    opacity: ${(props) => (props.isHidden ? '0.5' : '')};
    text-decoration: ${(props) => (props.isHidden ? 'line-through' : '')};
    & i {
        margin-right: 0.5rem;
    }
    &.day {
        min-width: 10rem;
        @media ${mediaQueries.s} {
            justify-content: flex-start;
        }
    }
    &.time {
        min-width: 10rem;
        font-weight: 800;
        @media ${mediaQueries.s} {
            justify-content: flex-end;
        }
    }
    &.name {
        min-width: 10rem;
        @media ${mediaQueries.s} {
            justify-content: flex-start;
        }
    }
    &.room {
        min-width: 13rem;
        text-align: left;
        @media ${mediaQueries.s} {
            width: 100%;
        }
    }
    &.buttons {
        min-width: 13rem;
        @media ${mediaQueries.s} {
            width: 100%;
        }
    }
`;
