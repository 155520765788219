import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import axios from 'common/axios';
import {
    API_KEY,
    AUTH_URL,
    SLUG_PASSWORD_RESET,
} from 'common/axiosSettings.js';
import * as actionCreators from 'store/actions/index';
import { isValidEmail } from 'common/utils';
import Backdrop from 'components/UI/Backdrop';
import Loading from 'components/UI/Loading';
import { Row, Box } from 'components/BasicComponents';

import LoginContent from './LoginContent';
import success from 'assets/images/success.svg';

const LoginScreen = ({
    signInToggler,
    closeLoginScreen,
    auth,
    token,
    openedLoginScreen,
    loading,
    error,
    regSuccess,
    signIn,
    regSuccessMessage,
}) => {
    const { t } = useTranslation();

    const [loginScreenMessage, setLoginScreenMessage] = useState('');
    const [passwordReset, setPasswordReset] = useState(false);

    const [user, setUser] = useState({
        email: '',
        password: '',
        name: '',
        surname: '',
        congregation: '',
    });

    const beforeSignInToggler = () => {
        setLoginScreenMessage('');
        signInToggler();
    };

    const closeLoginScreenFunc = () => {
        setLoginScreenMessage('');
        closeLoginScreen();
    };

    const changeHandler = (e) => {
        if (e.target.name === 'email') {
            e.target.value = e.target.value.toLowerCase();
        }
        setUser((user) => ({ ...user, [e.target.name]: e.target.value }));
    };

    const validationCheck = (type) => {
        if (!isValidEmail(user.email)) {
            setLoginScreenMessage(t('Zadaný e-mail není správný'));
            return false;
        }
        if (user.password.trim().length < 6) {
            setLoginScreenMessage(t('Heslo musí mít nejméně 6 znaků'));
            return false;
        }
        if (
            type === 'signUp' &&
            (user.name.trim() === '' ||
                user.surname.trim() === '' ||
                user.congregation.trim() === '')
        ) {
            setLoginScreenMessage(t('Musíš vyplnit jméno, příjmení i sbor'));
            return false;
        }
        return true;
    };

    const postAuth = (e, type) => {
        e.preventDefault();
        if (validationCheck(type)) {
            const typePost = type === 'signUp' ? 'signUp' : 'signIn';
            setLoginScreenMessage('');
            setUser((user) => ({
                ...user,
                email: '',
                password: '',
                name: '',
                surname: '',
                congregation: '',
            }));
            auth({
                email: user.email,
                password: user.password,
                name: user.name,
                surname: user.surname,
                congregation: user.congregation,
                type: typePost,
            });
        }
    };

    const passwordResetF = () => {
        if (user.email.trim() === '') {
            setLoginScreenMessage(t('Zadej tvůj email'));
            return false;
        }
        if (!isValidEmail(user.email)) {
            setLoginScreenMessage(t('Zadaný email není správný'));
            return false;
        }
        setLoginScreenMessage('');
        setPasswordReset(true);
        const data = {
            email: user.email,
            requestType: 'PASSWORD_RESET',
        };
        const url = `${AUTH_URL}${SLUG_PASSWORD_RESET}${API_KEY}`;

        axios
            .post(url, data)
            .then((response) => {
                setLoginScreenMessage(
                    t('Byl ti odeslán email na obnovení hesla')
                );
            })
            .catch((error) => {
                let errorMessage = t('Nešlo to, skus to za chvíli');
                if (
                    error?.response?.data?.error?.message === 'EMAIL_NOT_FOUND'
                ) {
                    errorMessage = t('Účet s tímhle emailem neexistuje');
                }
                setLoginScreenMessage(errorMessage);
                setPasswordReset(false);
            });
    };

    const content =
        token || regSuccess ? (
            <SuccessStyled />
        ) : (
            <LoginContent
                signIn={signIn}
                user={user}
                changeHandler={changeHandler}
                postAuth={postAuth}
                passwordReset={passwordReset}
                passwordResetF={passwordResetF}
                beforeSignInToggler={beforeSignInToggler}
                message={loginScreenMessage}
                errorMessage={error}
                regSuccessMessage={regSuccessMessage}
            />
        );

    return (
        <>
            <Backdrop
                onClick={closeLoginScreenFunc}
                isOpened={openedLoginScreen}
                isLoginBackdrop
            />
            <LoginScreenStyled
                onClick={closeLoginScreenFunc}
                isOpened={openedLoginScreen}
            >
                <LoginBlockStyled onClick={(e) => e.stopPropagation()}>
                    {loading ? (
                        <LoadingWrapperStyled>
                            <Loading />
                        </LoadingWrapperStyled>
                    ) : (
                        content
                    )}
                </LoginBlockStyled>
            </LoginScreenStyled>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        token: state.auth.token,
        openedLoginScreen: state.auth.openedLoginScreen,
        loading: state.auth.loading,
        error: state.auth.errorMessage,
        regSuccess: state.auth.regSuccess,
        signIn: state.auth.signIn,
        regSuccessMessage: state.auth.regSuccessMessage,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        signInToggler: () => dispatch(actionCreators.signInToggler()),
        closeLoginScreen: () => dispatch(actionCreators.closeLoginScreen()),
        auth: (userData) => dispatch(actionCreators.auth(userData)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginScreen);

const LoginScreenStyled = styled(Box)`
    width: 100%;
    height: 50%;
    min-height: 350px;
    background: #fff;
    justify-content: flex-end;
    align-items: center;
    position: fixed;
    padding: 5px;
    z-index: ${(props) => props.theme.zIndex.loginScreen};
    transition: all 0.2s;
    box-shadow: 0 0 15px 1px rgba(0, 0, 0, 0.2);
    transform: ${(props) =>
        props.isOpened ? 'translateY(0)' : 'translateY(-110%)'};
`;

const LoginBlockStyled = styled(Box)`
    width: 30%;
    min-width: 200px;
    min-height: 310px;
    justify-content: flex-end;
    align-items: center;
`;

const SuccessStyled = styled(Row)`
    background: url(${success}) center center no-repeat;
    background-size: contain;
    width: 60%;
    height: 100%;
`;
const LoadingWrapperStyled = styled(Row)`
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
    padding-bottom: 3.2rem;
`;
