import cloneDeep from 'lodash/cloneDeep';

export const localStoragePersist = {
    set: (name, myObject) =>
        localStorage.setItem(name, JSON.stringify(myObject)),
    get: (name) =>
        localStorage.getItem(name)
            ? JSON.parse(localStorage.getItem(name))
            : {},
};

export const isValidEmail = (email) => {
    const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
};

export const genericSort = (arr, dec, type, par) => {
    const getValue = (a, par) => {
        if (par === 'weekValue') {
            return a?.rows?.find((r) => r?.type === 'date').value;
        }
        return a[par];
    };

    arr.sort((a, b) => {
        let A = par ? getValue(a, par) : a;
        let B = par ? getValue(b, par) : b;
        if (type === 'text') {
            return dec ? A.localeCompare(B) * -1 : A.localeCompare(B);
        } else if (type === 'num') {
            A = +A;
            B = +B;
        } else if (type === 'time') {
            A = new Date(`2022-01-01T${A}:00Z`);
            B = new Date(`2022-01-01T${B}:00Z`);
        } else if (type === 'date') {
            A = new Date(`${A}T00:00:00Z`);
            B = new Date(`${B}T00:00:00Z`);
        }

        if (A > B) {
            return dec ? -1 : 1;
        }
        if (A < B) {
            return dec ? 1 : -1;
        }
        return 0;
    });

    return arr;
};

export const getDate = () => {
    const nowArr = new Date().toISOString().split('T')[0].split('-');

    const year = +nowArr[0];
    const month = +nowArr[1];
    const day = +nowArr[2];

    const yearPrev = year - 1;
    const monthPrev = month === 1 ? 12 : month - 1;

    return {
        year: year,
        month: month,
        day: day,
        monthPrev: monthPrev,
        yearPrev: yearPrev,
    };
};

export const isWithinWeek = (date) => {
    return (
        Date.parse(date) + 86400000 >= Date.now() &&
        Date.parse(date) <= Date.now() + 518400000
    );
};

export const isWeekFrom = (date) => {
    const dateBegining = Date.parse(
        new Date(new Date(date).setHours(0, 0, 0, 0))
    );

    return dateBegining < Date.now() && dateBegining + 604800000 > Date.now();
};

export const isOldWeek = (date) => {
    const day = new Date().getDay() === 0 ? 6 : new Date().getDay() - 1;
    const sum = day * 86400000 - 1;

    return Date.parse(date) < Date.now() - sum;
};

export const isOldWeekFrom = (date) => {
    const day = new Date().getDay() === 0 ? 7 : new Date().getDay();
    const sum = day * 86400000;

    return Date.parse(date) < Date.now() - sum;
};

export const isOldDay = (date) => {
    return Date.parse(date) + 86400000 < Date.now();
};

export const round = (val) => {
    if (!val || !val.length) {
        return 0;
    }

    return (
        Math.round(
            (val.reduce((acc, cur) => +acc + +cur, 0) + Number.EPSILON) * 100
        ) / 100
    );
};

const namesArePartlySame = (name1, name2) => {
    if (!name1?.length || !name2?.length) return false;
    const sameIndex = Math.min(name1?.length, name2?.length);
    return name1.slice(0, sameIndex) === name2.slice(0, sameIndex);
};

export const formatName = (publisher, surnameFellows) => {
    const publisherID = publisher?.publisherID;
    const surname = publisher?.surname || '';
    const name = publisher?.name || '';
    if (!name?.length || !surname?.length) {
        return '';
    }
    const oneSurnameFellows = surnameFellows.filter(
        (fellow) =>
            fellow.surname === surname && fellow.publisherID !== publisherID
    );

    if (!oneSurnameFellows?.length) {
        return `${surname} ${name.slice(0, 1)}.`;
    }

    for (let i = 1; i <= name.length; i++) {
        const fellowPublisher = oneSurnameFellows.find(
            (sur) => sur?.name.slice(0, i) === name.slice(0, i)
        );
        if (
            fellowPublisher?.name.length !== name?.length &&
            namesArePartlySame(fellowPublisher?.name, name)
        ) {
            return `${surname} ${name}`;
        }
        if (
            oneSurnameFellows.findIndex(
                (sur) => sur.name.slice(0, i) === name.slice(0, i)
            ) !== -1
        ) {
            continue;
        } else {
            return `${surname} ${name.slice(0, i)}${
                i !== name.length ? '.' : ''
            }`;
        }
    }
    return `${surname} ${name.slice(0, 1)}. ${publisher.suffix || ''}`;
};

export const formatISODate = (isoDate) => {
    if (!isoDate || !isoDate.includes('-')) {
        return null;
    }
    const dateArray = isoDate.split('-');
    return `${dateArray[2]}. ${dateArray[1]}. ${dateArray[0]}`;
};
export const formatISODateWithoutYear = (isoDate) => {
    if (!isoDate || !isoDate.includes('-')) {
        return null;
    }
    const dateArray = isoDate.split('-');
    return `${dateArray[2]}. ${dateArray[1]}.`;
};

export const createRowsFromSettings = (rowsFromSettings, rowsSaved) => {
    const rowsToRender = [];
    rowsFromSettings.forEach((row, i) => {
        const wantedSavedRow = rowsSaved.find((rowS) => rowS.id === row.id);
        let newRow = row;
        if (wantedSavedRow) {
            newRow = cloneDeep(wantedSavedRow);
        }
        rowsToRender.push(newRow);
    });
    return rowsToRender;
};

export const createSurnameFellowsArray = (publishers) => {
    const surnameFellows = [];
    publishers.forEach((publisher) => {
        if (
            publishers.filter(
                (pub) =>
                    pub.surname === publisher.surname &&
                    pub.publisherID !== publisher.publisherID
            ).length > 0
        ) {
            surnameFellows.push(publisher);
        }
    });
    return surnameFellows;
};

export const findNameConflicts = ({ data, sourceArray }) => {
    const { name, surname, suffix } = data;
    return sourceArray.filter(
        (srcItem) =>
            srcItem.name.trim().toLowerCase() === name.trim().toLowerCase() &&
            srcItem.surname.trim().toLowerCase() ===
                surname.trim().toLowerCase() &&
            srcItem.suffix?.trim().toLowerCase() ===
                suffix?.trim().toLowerCase()
    );
};

export const capitalizeName = (string) => {
    const lowercaseString = string.toLowerCase();
    return lowercaseString.charAt(0).toUpperCase() + lowercaseString.slice(1);
};
