import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import styled from 'styled-components';

import axios from 'common/axios';
import { Box } from 'components/BasicComponents';
import Input from 'components/UI/Input';
import Loading from 'components/UI/Loading';
import Button from 'components/UI/Button';

const KHSettings = (props) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({
        cgName: null,
    });

    const inputs = [
        {
            name: 'cgName',
            type: 'text',
            label: t('Názov zboru'),
            maxLength: 30,
            textAlign: 'left',
        },
    ];

    const token = props.auth.token;

    const settingsLoad = useCallback(() => {
        setLoading(true);
        const queryParams = `?auth=${token}`;
        axios
            .get(`settings.json${queryParams}`)
            .then((response) => {
                if (!response.data) {
                    setLoading(false);
                    return;
                } else {
                    setData((data) => ({
                        ...data,
                        cgName: response.data.cgName,
                    }));
                    setLoading(false);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }, [token]);

    useEffect(() => {
        settingsLoad();
    }, [settingsLoad]);

    const postSettings = () => {
        setLoading(true);
        const newData = data;
        axios
            .patch(`settings/.json?auth=${token}`, newData)
            .then((response) => {
                settingsLoad();
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
            });
    };

    if (loading) {
        return <Loading />;
    }

    return (
        <Box>
            <h1>{t('Nastavení sboru')}</h1>
            {inputs.map((input) => (
                <Box key={input.name}>
                    <LabelStyled htmlFor={input.name}>
                        {input.label}
                    </LabelStyled>
                    {(input.type === 'text' || input.type === 'time') && (
                        <Input
                            id={input.name}
                            type={input.type}
                            name={input.name}
                            placeholder={input.label}
                            maxLength={input.maxLength}
                            value={data[input.name]}
                            onChange={(e) =>
                                setData({
                                    ...data,
                                    [input.name]: e.target.value,
                                })
                            }
                            textAlign={input?.textAlign}
                        />
                    )}
                </Box>
            ))}
            <p>
                <Button
                    text={t('Uložiť')}
                    type="blueFull"
                    onClick={postSettings}
                    icon="save"
                />
            </p>
        </Box>
    );
};

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
    };
};

export default connect(mapStateToProps)(KHSettings);

const LabelStyled = styled.label`
    padding: 5px 0;
    margin-top: 4px;
`;
