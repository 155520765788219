import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import cloneDeep from 'lodash/cloneDeep';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useReactToPrint } from 'react-to-print';

import * as actionCreators from 'store/actions';
import useFetch from 'hooks/useFetch';
import { AUTHORIZATION } from 'common/constants';
import { genericSort } from 'common/utils';
import { Row, Box } from 'components/BasicComponents';
import Loading from 'components/UI/Loading';
import Button from 'components/UI/Button';
import { confirmModalAsync } from 'components/UI/ConfirmModal';

import MaintenanceEdit from './MaintenanceEdit';
import Maintenance from './Maintenance';

const processTalks = (data) => {
    const newData = [];
    Object.keys(data).forEach((id) => {
        newData.push({
            date: data[id].date,
            task: data[id].task,
            name: data[id].name,
            createdBy: data[id].createdBy,
            endDate: data[id]?.endDate,
            finished: data[id]?.finished,
            id: id,
        });
    });
    return newData;
};

const Maintenances = (props) => {
    const { t } = useTranslation();

    const [talk, setTalk] = useState({
        task: '',
        name: '',
        endDate: '',
        finished: false,
    });
    const [isPrinting, setIsPrinting] = useState(false);
    const [editable, setEditable] = useState('');
    const [message, setMessage] = useState('');
    const [talks, setTalks] = useState([]);
    const [hideFinished, setFinished] = useState(true);

    const { data, loading, sendRequest } = useFetch();

    const token = props.auth.token;
    const name = props.auth.name;
    const surname = props.auth.surname;

    const canEdit = token;
    const canSeeAuthors = props.auth?.authorization.includes(
        AUTHORIZATION.ADMIN
    );

    const changeInput = (e) => {
        setTalk((talk) => ({
            ...talk,
            [e.target.name]: e.target.value,
        }));
    };

    const loadTalks = useCallback(
        () =>
            sendRequest({
                slug: 'maintenance',
                method: 'get',
                token,
                processData: processTalks,
            }),
        [sendRequest, token]
    );
    const postTalks = (data) =>
        sendRequest({
            slug: 'maintenance',
            method: 'post',
            token,
            data,
            onCompleted: loadTalks,
        });
    const patchTalks = (id, data) =>
        sendRequest({
            slug: `maintenance/${id}`,
            method: 'patch',
            token,
            data,
            onCompleted: loadTalks,
        });
    const deleteTalks = (id, onCompleted) =>
        sendRequest({
            slug: `maintenance/${id}`,
            method: 'delete',
            token,
            onCompleted,
        });

    useEffect(() => {
        setTalks(data);
    }, [data]);

    useEffect(() => {
        loadTalks();
    }, [loadTalks]);

    const postHandler = () => {
        const data = {
            date: new Date().toISOString().split('T')[0],
            createdBy: `${surname} ${name}`,
            ...talk,
        };
        postTalks(data);
        setTalk({ task: '', name: '', endDate: '', finished: false });
    };

    const beforeDeleteTalk = async (id) => {
        const confirmResult = await confirmModalAsync({
            props: props,
            title: t('Odstranit úlohu'),
            question: t('Opravdu chceš odstranit úlohu?'),
        });

        if (confirmResult) {
            deleteTalk(id);
        }
    };

    const deleteTalk = (id) => {
        const newTalks = talks.filter((item) => item.id !== id);
        deleteTalks(id, setTalks(newTalks));
    };

    const talkEdit = (e, id) => {
        const wantedIndex = talks.findIndex((row) => row.id === id);
        const newTalks = cloneDeep(talks);
        const realValue =
            e.target.name === 'finished' ? e.target.checked : e.target.value;
        newTalks[wantedIndex][e.target.name] = realValue;
        console.log(newTalks);

        setTalks(newTalks);
    };

    const postEditedTalk = (id) => {
        const data = talks.find((talk) => talk.id === id);
        patchTalks(id, data);
        setEditable('');
    };

    const componentRef = useRef();
    const doPrint = useReactToPrint({
        content: () => componentRef.current,
    });
    const handlePrint = () => {
        setIsPrinting(true);
    };

    useEffect(() => {
        if (isPrinting) {
            doPrint();
            setTimeout(() => setIsPrinting(false), 100);
        }
    }, [isPrinting, doPrint]);

    const hasFinishedTalks = !!talks?.filter((talk) => talk.finished)?.length;
    const talksToShow =
        hideFinished && !editable
            ? talks.filter((talk) => !talk.finished)
            : talks;
    const sortedTalks = genericSort(talksToShow, false, 'date', 'date');

    const maintenanceList = [];
    sortedTalks.forEach((maintenance) => {
        maintenanceList.push(
            <Maintenance
                data={maintenance}
                key={maintenance.id}
                isEditable={editable === maintenance.id}
                edit={(e) => talkEdit(e, maintenance.id)}
                blueButton={
                    editable === maintenance.id
                        ? () => postEditedTalk(maintenance.id)
                        : () => setEditable(maintenance.id)
                }
                redButton={
                    editable === maintenance.id
                        ? () => setEditable(false)
                        : () => beforeDeleteTalk(maintenance.id)
                }
                isPrinting={isPrinting}
                canWrite={canEdit}
                canDelete={canEdit}
                canSeeAuthors={canSeeAuthors}
            />
        );
    });

    return (
        <div>
            <Routes>
                <Route
                    path="edit"
                    element={
                        canEdit ? (
                            <MaintenanceEdit
                                change={changeInput}
                                postHandler={postHandler}
                                data={talk}
                                message={message}
                            />
                        ) : (
                            <Navigate to="/maintenance" />
                        )
                    }
                />
            </Routes>

            <Button
                text={t('Vytlačit')}
                type={'purpleFull'}
                icon={'print'}
                onClick={handlePrint}
            />
            <Box ref={componentRef}>
                <h1>{t('Rozpis údržby')}</h1>

                {hasFinishedTalks && !isPrinting && (
                    <Row>
                        <Button
                            text={
                                hideFinished
                                    ? t('Zobrazit splněné')
                                    : t('Skrýt splněné')
                            }
                            type={hideFinished ? 'blue' : 'orange'}
                            icon={'history'}
                            onClick={() =>
                                setFinished((hideFinished) => !hideFinished)
                            }
                            my={'0.5rem'}
                        />
                    </Row>
                )}

                {loading && <Loading />}
                {!!sortedTalks.length && (
                    <Maintenance isLabel canSeeAuthors={canSeeAuthors} />
                )}
                {maintenanceList}
            </Box>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        openModal: (component, componentProps, title) =>
            dispatch(
                actionCreators.openModal(component, componentProps, title)
            ),
        closeModal: () => dispatch(actionCreators.closeModal()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Maintenances);
