export const AUTHORIZATION = {
    APPROVED: 'approved',
    ADMIN: 'admin',
    RESERVATION_WRITE: 'reservationWrite',
    RESERVATION_DELETE: 'reservationDelete',
    SERVICE_MEETINGS_WRITE: 'serviceMeetingsWrite',
    MAINTENANCE_WRITE: 'maintenanceWrite',
    MAINTENANCE_EDIT: 'maintenanceEdit',
    MAINTENANCE_DELETE: 'maintenanceDelete',
    EDIT_USERS: 'editUsers',
};

export const DAYS = {
    1: 'Pondělí',
    2: 'Úterý',
    3: 'Středa',
    4: 'Čtvrtek',
    5: 'Pátek',
    6: 'Sobota',
    7: 'Neděle',
};

export const MONTHS = {
    1: 'Leden',
    2: 'Únor',
    3: 'Březen',
    4: 'Duben',
    5: 'Květen',
    6: 'Červen',
    7: 'Červenec',
    8: 'Srpen',
    9: 'Září',
    10: 'Říjen',
    11: 'Listopad',
    12: 'Prosinec',
};

export const ROUTES = {
    HOME: '/reservations',
    SCHEDULE: '/service-meetings',
    MAINTENANCE: '/maintenance',
    HOME_EDIT: '/reservation/edit',
    SCHEDULE_EDIT: '/service-meetings/edit',
    MAINTENANCE_EDIT: '/maintenance/edit',
    KH_SETTINGS: '/admin/kh-settings',
    USERS: '/admin/users',
    CHANGELOG: '/my-account/help',
    SETTINGS: '/my-account/settings',
};

export const DATABASE_KEYS = {
    RESERVATION: 'reservations',
    SERVICE_MEETINGS: 'service-meetings',
    MAINTENANCE: '/maintenance',
    USERS: 'users',
    DB_VERSION: 'version',
};
