import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import cloneDeep from 'lodash/cloneDeep';
import { useTranslation } from 'react-i18next';

import axios from 'common/axios';
import { AUTHORIZATION } from 'common/constants';
import * as actionCreators from 'store/actions';
import { Box } from 'components/BasicComponents';
import Loading from 'components/UI/Loading';
import { confirmModalAsync } from 'components/UI/ConfirmModal';

import User from './User';

const Users = (props) => {
    const { t } = useTranslation();

    const [loading, setLoading] = useState(false);
    const [editable, setEditable] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [users, setUsers] = useState([]);

    const token = props.auth.token;

    const usersLoad = useCallback(() => {
        setLoading(true);
        const queryParams = `?auth=${token}`;
        axios
            .get(`users.json${queryParams}`)
            .then((response) => {
                const newUsers = [];
                if (!response?.data) {
                    setLoading(false);
                    return;
                } else {
                    Object.keys(response.data).forEach((id) => {
                        newUsers.push({
                            email: response.data[id].email,
                            name: response.data[id].name,
                            surname: response.data[id].surname,
                            congregation: response.data[id].congregation,
                            authorization: {
                                [AUTHORIZATION.APPROVED]: false,
                                [AUTHORIZATION.ADMIN]: false,
                                [AUTHORIZATION.RESERVATION_WRITE]: false,
                                [AUTHORIZATION.RESERVATION_DELETE]: false,
                                [AUTHORIZATION.SERVICE_MEETINGS_WRITE]: false,
                                [AUTHORIZATION.MAINTENANCE_WRITE]: false,
                                [AUTHORIZATION.MAINTENANCE_EDIT]: false,
                                [AUTHORIZATION.MAINTENANCE_DELETE]: false,
                                [AUTHORIZATION.EDIT_USERS]: false,
                                ...(response.data[id]?.authorization && {
                                    ...response.data[id].authorization,
                                }),
                            },
                            userID: response.data[id].userID,
                            id: id,
                        });
                    });
                    setUsers(newUsers);
                    setLoading(false);
                }
            })
            .catch((error) => {
                setLoading(false);
            });
    }, [token]);

    useEffect(() => {
        usersLoad();
    }, [usersLoad]);

    const beforeRemoveHandler = async (user) => {
        const confirmResult = await confirmModalAsync({
            props: props,
            title: t('Odstrániť používateľa'),
            question: `${t('Naozaj chceš odstrániť používateľa')} ${
                user.name
            } ${user.surname}?`,
        });

        if (confirmResult) {
            removeHandler(user);
        }
    };

    const removeHandler = (user) => {
        axios
            .delete(`users/${user.id}.json?auth=${token}`)
            .then((response) => {
                usersLoad();
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const userEdit = (e, id) => {
        const wantedIndex = users.findIndex((row) => row.id === id);
        const newUsers = cloneDeep(users);

        if (e.target.name === 'authorization') {
            newUsers[wantedIndex].authorization = {
                ...newUsers[wantedIndex].authorization,
                [e.target.id]: e.target.checked,
            };
        } else if (e.target.name === 'approved') {
            const value = e.target.value === 'approved' ? true : false;
            newUsers[wantedIndex].authorization = {
                ...newUsers[wantedIndex].authorization,
                approved: value,
            };
        } else {
            newUsers[wantedIndex][e.target.name] = e.target.value;
        }
        setUsers(newUsers);
    };

    const postEditedUser = (id) => {
        const publisherID = users.find((user) => user.id === id).publisherID;
        if (
            publisherID &&
            users.filter((user) => user.publisherID === publisherID).length > 1
        ) {
            setErrorMessage(`ID "${publisherID}" už je obsadené`);
            return;
        }
        setErrorMessage('');
        const data = users.find((user) => user.id === id);
        delete data.id;
        axios
            .patch(`users/${id}.json?auth=${token}`, data)
            .then((response) => {
                setEditable('');
                usersLoad();
            })
            .catch((error) => console.log(error));
    };

    if (loading) {
        return <Loading />;
    }

    const usersList = users.map((user, index) => (
        <User
            key={user.id}
            data={user}
            index={index}
            isEditable={editable === user.id}
            errorMessage={errorMessage}
            blueButton={
                editable === user.id
                    ? () => postEditedUser(user.id)
                    : () => setEditable(user.id)
            }
            redButton={
                editable === user.id
                    ? () => setEditable('')
                    : () => beforeRemoveHandler(user)
            }
            edit={(e) => userEdit(e, user.id)}
        />
    ));

    return (
        <Box>
            <h1>{t('Registrovaní uživatelé')}</h1>
            <p>
                {t('Počet registrovaných')}: <strong>{usersList.length}</strong>
            </p>
            {!!usersList.length && <User isLabel />}
            {usersList}
        </Box>
    );
};

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        openModal: (component, componentProps, title) =>
            dispatch(
                actionCreators.openModal(component, componentProps, title)
            ),
        closeModal: () => dispatch(actionCreators.closeModal()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Users);
