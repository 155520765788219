import React from 'react';
import styled from 'styled-components';

import { Box } from '../BasicComponents';
import closeIcon from '../../assets/images/hamclose.svg';

const CloseButton = ({ onClick, top, right }) => (
    <CloseButtonStyled onClick={onClick} top={top} right={right}>
        <Box></Box>
    </CloseButtonStyled>
);

export default CloseButton;

const CloseButtonStyled = styled(Box)`
    width: 20px;
    height: 20px;
    position: absolute;
    top: ${(props) => props.top || '1rem'};
    right: ${(props) => props.right || '1rem'};
    justify-content: center;
    align-items: center;
    overflow: hidden;
    cursor: pointer;

    & div {
        background: url(${closeIcon}) no-repeat center center;
        width: 20px;
        height: 20px;
        transition: all 0.2s ease-out;
    }
    &:hover div {
        transform: rotate(90deg);
    }
`;
