import React from 'react';
import styled from 'styled-components';

import { mediaQueries } from 'common/theme';
import { Row } from 'components/BasicComponents';
import { Icons } from 'common/Icons';

const Button = ({
    mini,
    disabled,
    type,
    icon,
    onClick,
    onMouseEnter,
    onMouseLeave,
    hideText,
    text,
    px,
    py,
    mx,
    my,
    height,
}) => {
    const isFull = type?.includes('Full') || disabled;
    const colorName =
        isFull && !disabled
            ? type?.slice(0, type?.indexOf('Full'))
            : type
            ? type
            : 'disabled';

    return (
        <ButtonStyled
            onClick={onClick}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            mini={mini}
            disabled={disabled}
            hideText={hideText}
            px={px}
            py={py}
            mx={mx}
            my={my}
            height={height}
            text={text}
            type={type}
            colorName={colorName}
            isFull={isFull}
        >
            {!!icon && <i className={Icons[icon]} />}
            {!!text && <RowStyled hideText={hideText}>{text}</RowStyled>}
        </ButtonStyled>
    );
};

export default Button;

const ButtonStyled = styled.button`
    height: ${(props) => props.height || 'auto'};
    max-width: 50rem;
    background: ${(props) =>
        props.isFull
            ? props.theme.colors.buttons[props.colorName].light
            : 'transparent'};
    color: ${(props) =>
        props.isFull
            ? props.theme.colors.buttons?.[props.colorName]?.color
                ? props.theme.colors.buttons[props.colorName].color
                : '#fff'
            : props.theme.colors.buttons[props.colorName].dark};
    border: 0;
    outline: none;
    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
    border-radius: ${(props) => (props.noRadius ? 0 : `0.4rem`)};
    font-family: 'DMSans', calibri, tahoma;
    font-weight: 800;
    font-size: 1.2rem;
    transition: background 0.2s;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: none;
    padding: ${(props) =>
        `${props.mini ? '2px' : props.py || '5px'} ${props.px || '10px'}`};
    margin: ${(props) =>
        `${props.my || '0px'} ${props.mini ? '5px' : props.mx || '0px'}`};

    @media ${mediaQueries.xs} {
        font-size: ${(props) =>
            props.hideText || !props.text ? '1.5rem' : '1.2rem'};
    }
    & i {
        transition: transform 0.2s;
        margin-right: ${(props) => (props.text ? '.5rem' : '')};
    }
    &:hover {
        background: ${(props) =>
            props.theme.colors.buttons[props.colorName].dark};
        color: ${(props) =>
            props.theme.colors.buttons?.[props.colorName]?.hoverColor
                ? props.theme.colors.buttons[props.colorName].hoverColor
                : '#fff'};
        & i {
            transform: scale(1.3);
        }
    }

    &:disabled {
        border: 1px solid
            ${(props) => props.theme.colors.buttons.disabled.color};
        &:hover {
            & i {
                transform: scale(1);
            }
        }
    }
`;

const RowStyled = styled(Row)`
    @media ${mediaQueries.xs} {
        display: ${(props) => (props.hideText ? 'none' : '')};
    }
`;
