import React from 'react';
import { useTranslation } from 'react-i18next';
import styled, { keyframes } from 'styled-components';
import { Routes, Route } from 'react-router-dom';

import { theme, mediaQueries } from 'common/theme';
import { formatISODateWithoutYear } from 'common/utils';
import { Row } from 'components/BasicComponents';
import { Icons } from 'common/Icons';
import Button from 'components/UI/Button';
import Input from 'components/UI/Input';
import { rooms } from './ReservationEdit';

export const backgrounds = {
    '01': '#FFFFFF',
    '02': '#fbfb74',
    '03': '#b4fb74',
    '04': '#fb7474',
};

const Reservation = ({
    data,
    edit,
    isEditable,
    blueButton,
    redButton,
    isLabel,
    isPrinting,
    canWrite,
    canDelete,
}) => {
    const { t } = useTranslation();
    const { date, time, event, room, congregation, author } = data || {};

    const renderedBackground = backgrounds[room];

    if (isLabel) {
        return (
            <ReservationStyled isLabel>
                <WrapperStyled>
                    <ColumnStyled className="date">{t('Datum')}</ColumnStyled>
                    <ColumnStyled className="time">{t('Čas')}</ColumnStyled>
                    <ColumnStyled className="event">
                        {t('Událost')}
                    </ColumnStyled>
                    <ColumnStyled className="congregation">
                        {t('Sbor')}
                    </ColumnStyled>
                    <ColumnStyled className="author">
                        {t('Zapsal')}
                    </ColumnStyled>
                    <ColumnStyled className="buttons"></ColumnStyled>
                </WrapperStyled>
            </ReservationStyled>
        );
    }

    const today = new Date().toISOString().split('T')[0];
    const isToday = today === date;
    const isActive = !isPrinting && isToday;

    const reservation = (showButtons) => {
        return (
            <ReservationStyled
                isEditable={isEditable}
                isActive={isActive}
                bg={renderedBackground}
            >
                <WrapperStyled>
                    <Row>{(date || isEditable) && (
                        <ColumnStyled className="date">
                            <i className={Icons.calendar} />
                            {isEditable ? (
                                <Input
                                    type="date"
                                    value={date}
                                    name="date"
                                    onChange={(e) => edit(e)}
                                />
                            ) : (
                                formatISODateWithoutYear(date)
                            )}
                        </ColumnStyled>
                    )}

                    <ColumnStyled className="time">
                        <i className={Icons.clock} />
                        {isEditable ? (
                            <Input
                                type="time"
                                value={time}
                                name="time"
                                onChange={(e) => edit(e)}
                                width="100px"
                            />
                        ) : (
                            time
                        )}
                    </ColumnStyled></Row>

                    <ColumnStyled className="event">
                        {isEditable ? (
                            <Input
                                type="text"
                                value={event}
                                name="event"
                                onChange={(e) => edit(e)}
                            />
                        ) : (
                            event
                        )}
                    </ColumnStyled>

                    {isEditable && (
                        <Input
                            type="select"
                            value={room}
                            name="room"
                            onChange={(e) => edit(e)}
                        >
                            {rooms.map((room, i) => (
                                <option key={`${room.id}_${i}`} value={room.id}>
                                    {room.name}
                                </option>
                            ))}
                        </Input>
                    )}

                    <Row><ColumnStyled className="congregation">
                        {congregation}
                    </ColumnStyled>
                    <ColumnStyled className="author">{author}</ColumnStyled></Row>

                    {showButtons && (
                        <Routes>
                            <Route
                                path="edit"
                                element={
                                    canWrite || canDelete ? (
                                        <ColumnStyled className="buttons">
                                            {canWrite && (
                                                <Button
                                                    type={
                                                        isEditable
                                                            ? 'greenFull'
                                                            : 'blueFull'
                                                    }
                                                    onClick={blueButton}
                                                    icon={
                                                        isEditable
                                                            ? 'save'
                                                            : 'edit'
                                                    }
                                                    mx="5px"
                                                    my="5px"
                                                />
                                            )}

                                            {!isEditable &&
                                            !canDelete ? null : (
                                                <Button
                                                    type="redFull"
                                                    onClick={redButton}
                                                    icon={
                                                        isEditable
                                                            ? 'cancel'
                                                            : 'delete'
                                                    }
                                                    mx="5px"
                                                    my="5px"
                                                />
                                            )}
                                        </ColumnStyled>
                                    ) : null
                                }
                            />
                        </Routes>
                    )}
                </WrapperStyled>
            </ReservationStyled>
        );
    };

    return reservation(!isPrinting);
};

export default Reservation;

const highlight = keyframes`
  0% {
    background: ${theme.colors.active.secondary};
  }
  50% {
    background: ${theme.colors.active.primary};
  }
  100% {
    background: ${theme.colors.active.secondary};
  }
`;

const ReservationStyled = styled(Row)`
    background: ${(props) =>
        props.isLabel
            ? `${props.theme.colors.label}22`
            : props.isEditable
            ? props.theme.colors.editable.background
            : props.bg};
    color: ${(props) => (props.isLabel ? props.theme.colors.label : '')};
    font-weight: ${(props) => (props.isLabel ? '800' : '')};
    width: 100%;
    max-width: 90rem;
    min-height: 2.5rem;
    line-height: 2.5rem;
    border-bottom: ${(props) =>
        props.isLabel ? 0 : `1px solid rgba(0, 0, 0, 0.1)`};
    padding: 0.5rem;
    animation-name: ${(props) => (props.isActive ? highlight : '')};
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    @media ${mediaQueries.s} {
        display: ${(props) => (props.isLabel ? 'none' : '')};
    }
`;

const WrapperStyled = styled(Row)`
    width: 100%;
    flex-wrap: wrap;
    @media ${mediaQueries.s} {
        flex-direction: column;
        align-items: center;
        padding: 0.5rem;
    }
`;

const ColumnStyled = styled(Row)`
    text-align: center;
    padding: 0 0.5rem;
    align-items: center;
    opacity: ${(props) => (props.isHidden ? '0.5' : '')};
    text-decoration: ${(props) => (props.isHidden ? 'line-through' : '')};
    & i {
        margin-right: 0.5rem;
    }
    &.date {
        min-width: 9rem;
        @media ${mediaQueries.s} {
            justify-content: center;
        }
    }
    &.time {
        min-width: 8rem;
        justify-content: center;
        text-align: center;
        @media ${mediaQueries.s} {
            justify-content: center;
        }
    }
    &.event {
        min-width: 25rem;
        font-weight: 800;
        @media ${mediaQueries.s} {
            justify-content: center;
        }
    }
    &.congregation {
        min-width: 13rem;
        @media ${mediaQueries.s} {
            justify-content: center;
        }
    }
    &.author {
        min-width: 14rem;
        text-align: left;
        @media ${mediaQueries.s} {
            width: 100%;
            justify-content: center;
        }
    }
    &.buttons {
        min-width: 11rem;
        text-align: left;
        @media ${mediaQueries.s} {
            width: 100%;
        }
    }
`;
