import React from 'react';
import { NavLink } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';

import { mediaQueries } from 'common/theme';
import { Icons } from 'common/Icons';

const resetScroll = () => {
    document.documentElement.scrollTop = 0;
    document.body.scrollTop = 0;
};

const NavLinkC = ({ icon, to, className = null }) => (
    <NavLink
        onClick={resetScroll}
        to={to}
        exact="exact"
        className={(data) =>
            [className, data.isActive ? 'active' : ''].join(' ')
        }
        aria-hidden="true"
    >
        <i className={Icons[icon]}></i>
    </NavLink>
);

const MobileMenu = ({ isAdmin, isApproved }) => (
    <MobileMenuStyled>
        {isApproved && (
            <>
                <NavLinkC icon={'calendar'} to="/reservations" />
                <NavLinkC icon={'bag'} to="/service-meetings" />
                <NavLinkC icon={'settings'} to="/maintenance" />
            </>
        )}
        {isApproved && isAdmin && <NavLinkC icon={'users'} to="/admin/users" />}
    </MobileMenuStyled>
);

export default MobileMenu;

const miniMenu = keyframes` 50% {
    transform: scale(1.8);
  }`;

const MobileMenuStyled = styled.nav`
    display: none;

    @media ${mediaQueries.s} {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 0.5rem;

        & a {
            margin: 0 0.6rem;
            width: 2.3rem;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            color: ${(props) => `${props.theme.colors.label}99`};
            font-size: 2rem;
            &.active {
                color: ${(props) => props.theme.colors.label};
                animation: ${miniMenu} 0.2s linear;
                transform: scale(1.4);
            }
        }
    }
`;
